import React, { useEffect, useState } from "react";
import "./sorteo-mensual.css";
import getMonthNameByNumber from "../helpers/getNameByMonthNumber";
import { Loader } from "../components/Loader";
import { useTranslation } from "react-i18next";

export const NextCard = ({ next, accessToken }) => {
  const [myNumbers = [], setMyNumbers] = useState();
  const [loadNumbers, setLoadNumbers] = useState(false);
  const [hasNumbers, setHasNumbers] = useState(false);
  const [errors, setErrors] = useState(false);
  const { t } = useTranslation();

  let numberList;
  let numGanador = next["num_ganador"];
  const dataImage = next["imagen"];

  if (numGanador === "") {
    numberList = [];
  } else {
    if (numGanador.indexOf(",") !== -1) {
      numberList = numGanador.split(",").map((item) => item.trim());
    } else {
      numberList = numGanador.split(" ");
    }
  }

  numberList.sort();

  let dateFull = next["fecha_sorteo"];
  let dateFullList = [];
  let dateYear;
  let dateMonth;
  let dateMonthNumber;

  dateFullList = dateFull.split("-", 3);
  let dateFormatted =
    dateFullList[2] + "/" + dateFullList[1] + "/" + dateFullList[0];

  dateYear = dateFullList[0];
  dateMonthNumber = parseInt(dateFullList[1]);
  if (dateMonthNumber - 1 < 1) {
    dateYear = (parseInt(dateYear) - 1).toString();
    dateMonthNumber = 12;
  } else {
    dateMonthNumber -= 1;
  }
  dateMonth = dateMonthNumber.toString();

  const urlEndpoint = `/campaign/promotion/technologicalRaffle/method/getTechnologicalRaffleHistoryRouletteByYearAndMonth?year=${dateYear}&month=${dateMonth}`;

  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.cod === "200") {
            let list = [];
            response.msg.map((item) => {
              list.push(item.num_sorteo);
              return null;
            });
            list.sort();
            setMyNumbers(list);
            setLoadNumbers(true);
            setHasNumbers(true);
          } else if (response.cod === "404") {
            setLoadNumbers(true);
          } else {
            setLoadNumbers(true);
            setErrors(true);
          }
        })
        .catch((error) => {
          setLoadNumbers(true);
          setErrors(true);
        });
    }
  });

  return (
    <div>
      <br />
      <div className="sm-card">
        <div className="sm-card-header">
          <h5 className="">{t("sorteomensual.nextcard.h5")}</h5>
          <h1 className="sm-titulos black">
            {getMonthNameByNumber(dateMonth)}
          </h1>
        </div>
        <h2 className="sm-titulos">{t("sorteomensual.nextcard.premio")}</h2>
        {dataImage !== "" ? (
          <img
            className="sm-img-premio"
            id="premio"
            src={`data:image/png;base64,${dataImage}`}
            alt="imagen promoción"
          />
        ) : (
          <span></span>
        )}
        <h3 className="sm-h3 regular">{next.nombre}</h3>
        <p className="sm-p regular">{next.observacion}</p>
        <div className="sm-sorteo">
          <h2 className="sm-h2 black">
            {t("sorteomensual.nextcard.h2.diadesorteo")}
          </h2>
          <span className="regular">{dateFormatted}</span>
        </div>
        <div className="sm-desplegable">
          <h2 className="sm-h2 black">
            {t("sorteomensual.nextcard.h2.tusnumeros")}
          </h2>
        </div>
        <div className="sm-number-list">
          {myNumbers.map((item) => {
            return (
              <span className="sm-number-list-item" key={item}>
                {item + " "}
              </span>
            );
          })}
          {!loadNumbers && <Loader showText={false} />}
          {loadNumbers && !hasNumbers && !errors && (
            <span className="bold">
              {" "}
              {t("sorteomensual.nextcard.errors.aunnotienesnumeros")}
            </span>
          )}
          {loadNumbers && !hasNumbers && errors && (
            <span className="bold">
              {" "}
              {t("sorteomensual.nextcard.errors.nosepudocargarlainformacion")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
