import React, { useEffect, useState } from "react";
import { ItemHistory } from "./ItemHistory";
import "./sorteo-mensual.css";
import { useLocation } from "react-router-dom";
import { Loader } from "../components/Loader";
import { useTranslation } from "react-i18next";

export const SorteoHistory = () => {
  const [loadingNumbers] = useState(false);
  const [history, setHistory] = useState([]);
  const [errors, setErrors] = useState(false);

  const location = useLocation();
  const accessToken = location.state.data;
  const { t } = useTranslation();

  var today = new Date();
  var dateYearNow = today.getFullYear();
  const [year, setYear] = useState(dateYearNow);
  const [loading, setLoading] = useState(false);

  const urlEndpoint = `/campaign/promotion/technologicalRaffle/method/getTechnologicalRaffleHistoryByYear?year=${year}`;

  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      setLoading(true);
      fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result["cod"] === "200") {
              setHistory(result["msg"]);
              setLoading(false);
            } else {
              setHistory([]);
              setLoading(false);
            }
          },
          (error) => {
            setHistory([]);
            setLoading(false);
          }
        )
        .catch((error) => {
          setHistory([]);
          setLoading(false);
          setErrors(true);
        });
    }
  }, [accessToken, year, urlEndpoint]);

  function changeYearSum() {
    if (!(year + 1 > dateYearNow) && !loading) setYear(year + 1);
  }

  function changeYearLess() {
    if (!(year - 1 < 2020) && !loading) setYear(year - 1);
  }

  return (
    <>
      <div className="sm-buttons-year-parent">
        <div className="sm-buttoms-year">
          <button>
            {" "}
            <i className="fas fa-chevron-left" onClick={changeYearLess}></i>
          </button>
          <div className="black sm-center-year">{year}</div>
          <button className="black sm-button-forward" onClick={changeYearSum}>
            {" "}
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <br></br>
      <br></br>
      {(loading || loadingNumbers) && (
        <Loader
          showText={true}
          textLoading={`${t(
            "sorteomensual.buttons.history.loading"
          )} ${year}`}
        />
      )}
      {history.length === 0 && !loading ? (
        !errors ? (
          <span className="sm-loading-text">
            {t("sorteomensual.buttons.history.errors.nohaysorteosesteano")}
          </span>
        ) : (
          <span className="sm-loading-text">
            {t("sorteomensual.nextcard.errors.nosepudocargarlainformacion")}
          </span>
        )
      ) : (
        !loading &&
        !loadingNumbers && (
          <div className="sm-sorteoHistoryItem">
            {history.map((item) => {
              return (
                <ItemHistory
                  key={item.id_sorteo}
                  history={item}
                  accessToken={accessToken}
                ></ItemHistory>
              );
            })}
          </div>
        )
      )}
    </>
  );
};
