import prizeIcon from "../assets/images/sorteo-mensual/prize-icon.svg";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

export const DialogPremiado = ({ openModal, toggleModal, data }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="sm-mymodal"
      overlayClassName="sm-myoverlay"
      closeTimeoutMS={200}
    >
      <center>
        <div className="sm-titulo-alert-parent">
          <span className="sm-titulo-alert black">
            {t("sorteomensual.buttons.dialogopremiado.premioobtenido")}
          </span>
        </div>
        <div className="sm-texto-contenido-alert-participar-parent sm-margin-1">
          <div>
            <img src={prizeIcon} alt="" />
          </div>

          <div className="sm-mt-4">
            <span className="regular">
              {t("sorteomensual.buttons.dialogopremiado.tunumero")}
            </span>
            <span className="black sm-num-premiado"> {data.num_sorteo}</span>
            <span className="regular">
              {" "}
              {t("sorteomensual.buttons.dialogopremiado.hasido")}
            </span>
            <br />
            <span className="regular">
              {" "}
              {t("sorteomensual.buttons.dialogopremiado.premiadoenel")}
            </span>
            <span className="black">
              {" "}
              {t("sorteomensual.buttons.dialogopremiado.sorteomensualde")}{" "}
              {data.mes}
            </span>
          </div>
          <div className="sm-mt-2">
            <span className="regular">
              {t(
                "sorteomensual.buttons.dialogopremiado.nospondremosencontactocontigomuyprontoparahacertellegartupremio"
              )}
            </span>
          </div>
        </div>
        <div className="sm-boton-como-funciona-alert-parent">
          <button onClick={toggleModal} className="sm-boton bold">
            {t("sorteomensual.buttons.dialogoparticipar.aceptar")}
          </button>
        </div>
      </center>
    </Modal>
  );
};
