import Modal from "react-modal";
import iconRegalo from "../assets/images/aniversario10/regalo-icon.svg";
import './aniversario10.css';

Modal.setAppElement("#root");

export const DialogInfoRepostaje = ({ isOpen, toggleModal, litrosVenta, litrosVentaDecimales }) => {
    function BasesLegales() {
        window.navigation.postMessage("web,https://petroprix.com/10aniversario/terminos-y-condiciones/,,,");
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodalinforepostaje modalinforepostaje"
            overlayClassName="myoverlay"
            closeTimeoutMS={200}
        >

            <div>

                <div className="anv-content-dialog">
                    <div className="center">
                        <span className="anv-dialog-title black" style={{textAlign: "left"}}>Con tu repostaje has conseguido:</span>
                    </div>
                    <div className="anv-dialog-info-litros">
                        <div className="anv-dialog-litros-cantidad black">{litrosVenta}<span className="anv-dialog-info-litros-decimals black">,{litrosVentaDecimales != null && litrosVentaDecimales !== 0 ? litrosVentaDecimales : "00"}</span></div>
                        <div className="anv-dialog-litros-info bold">Litros de combustible con descuento de 10 cént./L.</div>
                    </div>
                    <div className="anv-dialog-info-sorteo">
                        <ul className="anv-info-steps">
                            <span className='anv-step-item'>
                                <span className="anv-icon"><img src={iconRegalo} alt="" /></span>
                                <li className="anv-step black">¡Y hoy participas por un año de gasolina gratis!</li>
                            </span>
                        </ul>
                    </div>
                    <div className="anv-promo-info anv-modal-repostaje-bases">
                        <p className="anv-dialog-info link bold" onClick={BasesLegales}>Consulta las bases del aniversario aquí.</p>
                    </div>
                    <div className="anv-dialog-button" onClick={toggleModal}>Cerrar ventana</div>
                </div>
            </div>
        </Modal>
    );
}

