import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HelloAutoAppBar } from "../components/app_bar_helloauto";

export const TiradaCalcular = () => {
  const history = useNavigate();
  const location = useLocation();
  let data = location.state.data;

  function onBackClick() {
    history(-1);
  }

  return (
    <>
      <HelloAutoAppBar
        title="Promo Hello Auto"
        onBackClick={onBackClick}
        backgroundColor="#018bed"
      ></HelloAutoAppBar>
      <div>
        <embed className="th-embed" type="text/html" src={data} />
      </div>
    </>
  );
};
