// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rnf-not-found {
  text-align: center;
  padding-left: 5vw;
  padding-right: 5vw;
}
.img-ruleta-notf {
  width: 30vw;
  margin-right: 10vw;
}
.rnf-div-image {
  width: 100vw;
  align-items: center;
  text-align: center;
}
.rnf-title-tech {
  font-size: 1.5rem;
}
.rnf-left-align {
  width: 90vw;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/ruleta-not-found/ruleta-not-found.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".rnf-not-found {\n  text-align: center;\n  padding-left: 5vw;\n  padding-right: 5vw;\n}\n.img-ruleta-notf {\n  width: 30vw;\n  margin-right: 10vw;\n}\n.rnf-div-image {\n  width: 100vw;\n  align-items: center;\n  text-align: center;\n}\n.rnf-title-tech {\n  font-size: 1.5rem;\n}\n.rnf-left-align {\n  width: 90vw;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
