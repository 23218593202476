import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppBarGeneric } from "../components/app_bar_generic";
import { useTranslation } from "react-i18next";
import { SorteoHistory } from "./SorteoHistory";

export const HistoryPage = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  let accessToken = searchParams.get("token");

  const history = useNavigate();

  const handleBefore = () => {
    history(-1);
  };
  return (
    <div>
      <div className="sm-appbar-sorteo-mensual ">
        <AppBarGeneric
          title={t("sorteomensual.header")}
          backgroundColor="#f3f3f3"
          textColor="#2B363B"
          onBackClick={handleBefore}
        ></AppBarGeneric>
        <div className="sm-new-appbar"></div>
      </div>
      <div className="sm-center">
        <div>
          <SorteoHistory accessToken={accessToken}></SorteoHistory>
        </div>
      </div>
    </div>
  );
};
