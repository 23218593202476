import React from "react";
import "./ruleta-not-found.css";
import notfound from "../assets/images/ruleta-not-found/nogasolineras.svg";
import { useTranslation } from "react-i18next";

export const RuletaNotFound = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="rnf-not-found">
        <div className="rnf-div-image">
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <img src={notfound} className="img-ruleta-notf" alt="Notfound" />
        </div>
        <br></br>
        <br></br>
        <span className="black rnf-title-tech">
          {t("ruletanotfound.problematecnico")}
        </span>
        <br></br>
        <br></br>
        <br></br>

        <div className="rnf-left-align">
          <span className="regular">{t("ruletanotfound.error")}</span>
        </div>
      </div>
      <br></br>
      <br></br>
    </>
  );
};
