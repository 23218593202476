import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HelloAutoAppBar } from "../components/app_bar_helloauto";
import ruleta from "../assets/images/tirada/rul.png";
import party from "../assets/images/tirada/party.png";
import "./tirada.css";
import { useTranslation } from "react-i18next";

export const TiradaGratisHelloAuto2 = () => {
  const history = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  let data = location.state.data;
  let width = location.state.width;
  let height = location.state.height;

  function handleClickNext() {
    history("/tirada3", {
      state: {
        data: data,
        os: location.state.os,
      },
    });
  }
  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }
  function handleClickBefore() {
    history(-1);
  }

  return (
    <>
      <HelloAutoAppBar
        title="Promo Hello Auto"
        onBackClick={onBackClick}
        backgroundColor="#018bed"
      ></HelloAutoAppBar>

      <div className="th-parent-ha">
        <div className="th-div-rounded-ha-big2">
          <img
            src={ruleta}
            style={
              height / width > 1.5
                ? { marginTop: "5vh", top: "13vh" }
                : { top: "7vh" }
            }
            className="th-img-hello-ruleta"
            alt="Gasolina"
          />
        </div>
        <div className="th-div-icon-on-parent2">
          <div
            className="th-div-icon-on2"
            style={
              height / width > 1.5
                ? { marginTop: "14vh" }
                : { marginTop: "10vh" }
            }
          >
            <div className="th-text-tirada2-container-row">
              <img src={party} className="th-img-party" alt="Gasolina" />

              <div className="th-text2-tirada-ha">
                <span className="silka-regular th-texto-containerblue">
                  {t("promotirada.tirada2.llevateunatirada")}{" "}
                </span>
                <br></br>
                <span className="silka-black th-text-gratis">
                  {" "}
                  {t("promotirada.tirada2.gratis.mayusc")}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="th-subtitulo-bajo-gratis"
          style={
            height / width > 1.5 ? { marginTop: "-2vh" } : { marginTop: "-5vh" }
          }
        >
          <span className="silka-regular">
            {t("promotirada.tirada2.enlaruleta")}{" "}
          </span>
          <span className="silka-bold">
            {t("promotirada.tirada2.soloporcalcular")}
            <br></br>
            {t("promotirada.tirada2.elpreciodetupoliza")}
          </span>
        </div>
        <div className="th-row-button-ha-parent">
          <div className="th-row-buttons-ha">
            <div
              className="th-boton-siguiente-ha2 silka-bold"
              onClick={handleClickBefore}
            >
              {t("promotirada.tirada1.atras")}
            </div>
            <div
              className="th-boton-siguiente-ha1 silka-bold"
              onClick={handleClickNext}
            >
              {t("sorteomensual.buttons.dialogoparticipar.siguiente")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
