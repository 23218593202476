import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SorteoMensual } from "../sorteo-mensual/SorteoMensual";
import { HistoryPage } from "../sorteo-mensual/HistoryPage";
import { BlackFridayPrevious } from "../black-friday/BlackFriday";
import { TiradaGratisHelloAuto1 } from "../tirada-con-hello/tirada1";
import { TiradaGratisHelloAuto2 } from "../tirada-con-hello/tirada2";
import { TiradaGratisHelloAuto3 } from "../tirada-con-hello/tirada3";
import { TiradaGratisHelloAuto4 } from "../tirada-con-hello/tirada4";
import { TiradaGratisHelloAuto5 } from "../tirada-con-hello/tirada5";
import { PromocionTirada } from "../tirada-con-hello/promocion-tirada";
import { RuletaNotFound } from "../ruleta-not-found/RuletaNotFound";
import { ReyesMagos } from "../reyes-magos/ReyesMagos";
import { Aniversario10 } from "../Aniversario10/Aniversario10";
import { TiradaCalcular } from "../tirada-con-hello/tiradaCalcular";

export const AppRouter = () => {
  const router = (
    <Router>
      <Routes>
        <Route exact path="/sorteo-mensual" element={<SorteoMensual />}></Route>
        <Route
          exact
          path="/sorteo-mensual/history-page"
          element={<HistoryPage />}
        ></Route>
        <Route
          exact
          path="/black-finde"
          element={<BlackFridayPrevious />}
        ></Route>
        <Route
          exact
          path="/ruleta-not-found"
          element={<RuletaNotFound />}
        ></Route>
        <Route exact path="/promo-tirada" element={<PromocionTirada />}></Route>
        <Route
          exact
          path="/tirada1"
          element={<TiradaGratisHelloAuto1 />}
        ></Route>
        <Route
          exact
          path="/tirada2"
          element={<TiradaGratisHelloAuto2 />}
        ></Route>
        <Route
          exact
          path="/tirada3"
          element={<TiradaGratisHelloAuto3 />}
        ></Route>
        <Route
          exact
          path="/tirada4"
          element={<TiradaGratisHelloAuto4 />}
        ></Route>
        <Route
          exact
          path="/tirada5"
          element={<TiradaGratisHelloAuto5 />}
        ></Route>
        <Route
          exact
          path="/tirada-calcular"
          element={<TiradaCalcular />}
        ></Route>
        <Route exact path="/reyes-magos" element={<ReyesMagos />}></Route>
        <Route exact path="/aniversario10" element={<Aniversario10 />}></Route>
      </Routes>
    </Router>
  );
  return router;
};
