import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import historyIcon from "../assets/images/sorteo-mensual/history-icon.svg";
import infoIcon from "../assets/images/sorteo-mensual/info-icon.svg";
import legalIcon from "../assets/images/sorteo-mensual/legal-icon.svg";
import { DialogParticipar } from "./DialogParticipar";
import { useTranslation } from "react-i18next";

import "./sorteo-mensual.css";

export const Botones = ({ accessToken }) => {
  const [isOpenHowParticipate, setIsOpenHowParticipate] = useState(false);
  const history = useNavigate();
  const { t } = useTranslation();
  function handleClickBases() {
    window.navigation.postMessage(
      "web,https://petroprix.com/terminos-y-condiciones-la-ruleta,https://petroprix.com/terminos-y-condiciones-la-ruleta,,"
    );
  }
  function toggleModalHowToParticipate() {
    setIsOpenHowParticipate(!isOpenHowParticipate);
  }

  const handleNext = () => {
    history("/sorteo-mensual/history-page", {
      state: {
        data: accessToken,
      },
    });
  };

  return (
    <div className="sm-contenedor-botones modal-content sm-pb-5">
      <div className="sm-buttons-history">
        <div>
          <button className="sm-button-history" onClick={handleNext}>
            <img
              src={historyIcon}
              className="sm-imagen-svg sm-img-container"
              alt="historial"
            />
          </button>
          <span className="regular">
            {t("sorteomensual.buttons.historial")}
            <br></br> {t("sorteomensual.buttons.sorteos")}
          </span>
        </div>
        <div>
          <button
            className="sm-button-history"
            onClick={toggleModalHowToParticipate}
          >
            <img
              src={infoIcon}
              className="sm-imagen-svg sm-img-container"
              alt="participar"
            />
          </button>
          <span className="regular">
            {t("sorteomensual.buttons.como")}
            <br></br>
            {t("sorteomensual.buttons.participar")}
          </span>
        </div>

        <div>
          <button className="sm-button-history" onClick={handleClickBases}>
            <img
              src={legalIcon}
              className="sm-imagen-svg sm-img-container"
              alt="bases"
            />
          </button>

          <span className="regular">
            {t("sorteomensual.buttons.bases")}
            <br></br>
            {t("sorteomensual.buttons.legales")}
          </span>
        </div>
      </div>

      {isOpenHowParticipate && (
        <DialogParticipar
          isOpen={isOpenHowParticipate}
          toggleModal={toggleModalHowToParticipate}
        ></DialogParticipar>
      )}
    </div>
  );
};
