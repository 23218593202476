// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-bar-helloauto {
  height: 12vh;
  position: absolute;
  width: 100vw;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  z-index: 8;
}
.app-bar-generic {
  height: 3vh;
  position: fixed;
  width: 100vw;
  padding-top: 2vh;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  z-index: 8;
  white-space: nowrap;
}

.ReactModal__Overlay {
  z-index: 9 !important;
}

.app-bar-icono-color,
.app-bar-icono-color-new-appbar {
  margin-right: 5vw;
  text-align: center;
}

.parrafo-appbar,
.parrafo-new-appbar {
  font-size: 20px;
  margin-top: 2vh;
  margin-left: 5vw;
}

.parrafo-appbar {
  color: white;
}

.parrafo-new-appbar {
  color: inherit !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/app_bar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,UAAU;AACZ;AACA;EACE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;;EAEE,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".app-bar-helloauto {\n  height: 12vh;\n  position: absolute;\n  width: 100vw;\n  text-align: center;\n  align-items: center;\n  align-content: center;\n  display: flex;\n  z-index: 8;\n}\n.app-bar-generic {\n  height: 3vh;\n  position: fixed;\n  width: 100vw;\n  padding-top: 2vh;\n  text-align: center;\n  align-items: center;\n  align-content: center;\n  display: flex;\n  z-index: 8;\n  white-space: nowrap;\n}\n\n.ReactModal__Overlay {\n  z-index: 9 !important;\n}\n\n.app-bar-icono-color,\n.app-bar-icono-color-new-appbar {\n  margin-right: 5vw;\n  text-align: center;\n}\n\n.parrafo-appbar,\n.parrafo-new-appbar {\n  font-size: 20px;\n  margin-top: 2vh;\n  margin-left: 5vw;\n}\n\n.parrafo-appbar {\n  color: white;\n}\n\n.parrafo-new-appbar {\n  color: inherit !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
