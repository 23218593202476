import Modal from "react-modal";
import questionIcon from "../assets/images/reyes-magos/question-icon.svg";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");

export const DialogInfoReyesMagos = ({ isOpen, toggleModal }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="mymodalreyesmagos modalreyesmagos"
      overlayClassName="myoverlay"
      closeTimeoutMS={200}
    >
      <div>
        <div className="rm-content-dialog">
          <div className="center">
            <span className="rm-dialog-icon">
              <img src={questionIcon} alt="" />
            </span>
            <span className="rm-title-dialog rm-strong">
              {t("reyesmagos.dialogo.comomeidentificocomousuario")}
            </span>
          </div>
          <div className="rm-info-steps-dialog">
            <ul className="rm-info-steps">
              <span className="rm-step-number">1</span>
              <li className="rm-step">
                {t("reyesmagos.dialogo.enelsurtidorselecciona")}{" "}
                <span className="rm-strong">
                  "{t("reyesmagos.dialogo.identificarusuario")}"
                </span>
                .
              </li>
              <span className="rm-step-number">2</span>
              <li className="rm-step">
                {t("reyesmagos.dialogo.mediantelascamarastrataremosde")}{" "}
                <span className="rm-strong">
                  {t("reyesmagos.dialogo.reconocertumatrícula")}
                </span>
                .
              </li>
              <span className="rm-step-number">3</span>
              <li className="rm-step">
                {t("reyesmagos.dialogo.sinolaconseguimosreconocerpodras")}{" "}
                <span className="rm-strong">
                  {t("introducirlamanualmente")}
                </span>{" "}
                {t("reyesmagos.dialogo.enlapantalla")}.
              </li>
              <span className="rm-step-number">4</span>
              <li className="rm-step">
                <span className="rm-strong">
                  {t("reyesmagos.disfrutadeldescuento")}
                </span>
              </li>
            </ul>
          </div>
          <div className="rm-dialog-button" onClick={toggleModal}>
            {t("reyesmagos.dialogo.button.entendido")}
          </div>
        </div>
      </div>
    </Modal>
  );
};
