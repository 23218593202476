import "./app_bar.css";
import React from "react";
import { isAndroid } from "react-device-detect";

export const AppBarGeneric = (props) => {
  return (
    <div
      className="app-bar-generic"
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor,
      }}
    >
      <p className="parrafo-new-appbar black">
        <i
          onClick={props.onBackClick}
          className={
            isAndroid
              ? "fa fa-arrow-left app-bar-icono-color-new-appbar"
              : "fa fa-chevron-left app-bar-icono-color-new-appbar"
          }
        ></i>
        {props.title}
      </p>
    </div>
  );
};
