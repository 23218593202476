import Modal from "react-modal";
import iconQuestion from "../assets/images/black-friday/icon-question.svg";
import './black-friday.css';

Modal.setAppElement("#root");

export const DialogInfoBlackFinde = ({ isOpen, toggleModal }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodalblackfinde modalblackfinde"
            overlayClassName="myoverlay"
            closeTimeoutMS={200}
        >

            <div>

                <div className="bf-content-dialog">
                    <div className="center">
                        <span className="bf-dialog-icon"><img src={iconQuestion} alt="" /></span>
                        <span className="bf-title-dialog bf-strong" >¿Cómo me identifico como usuario?</span>
                    </div>
                    <div className="bf-info-steps-dialog">
                        <ul className="bf-info-steps">
                            <span className="bf-step-number">1</span><li className="bf-step">En el surtidor, selecciona <span className="bf-strong">"Identificar usuario"</span>.</li>
                            <span className="bf-step-number">2</span><li className="bf-step">Mediante las cámaras trataremos de <span className="bf-strong">reconocer tu matrícula</span>.</li>
                            <span className="bf-step-number">3</span><li className="bf-step">Si no la conseguimos reconocer, podrás <span className="bf-strong">introducirla manualmente</span> en la pantalla.</li>
                            <span className="bf-step-number">4</span><li className="bf-step">Selecciona tu opción de repostaje habitual y ... <span className="bf-strong">¡Disfruta del descuento!</span></li>
                        </ul>
                    </div>
                    <div className="bf-dialog-button" onClick={toggleModal}>Cerrar ventana</div>
                </div>
            </div>
        </Modal>
    );
}

