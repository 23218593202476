import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Botones } from "./Botones";
import { LastCard } from "./LastCard";
import { NextCard } from "./NextCard";
import { AppBarGeneric } from "../components/app_bar_generic";
import "./sorteo-mensual.css";
import { LoaderPetroprix } from "../components/LoaderPetroprix";
import { useTranslation } from "react-i18next";

export const SorteoMensual = () => {
  const [searchParams] = useSearchParams();
  let accessToken = searchParams.get("token");

  if (!accessToken) {
    accessToken = "";
  }
  const [next, setNext] = useState();
  const [last, setLast] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const urlEndpoint =
    "/campaign/promotion/technologicalRaffle/method/getTechnologicalRaffle";

  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      setLoading(true);
      fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result["cod"] === "200") {
              setLast(result["msg"]["last"]);
              setNext(result["msg"]["next"]);
              setLoading(false);
            } else {
              setLoading(false);
            }
          },
          (error) => {
            setLoading(false);
          }
        )
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [accessToken]);

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }

  return (
    <>
      <div className="appbar-sorteo-mensual ">
        <AppBarGeneric
          title={t("sorteomensual.header")}
          backgroundColor="#f3f3f3"
          textColor="#2B363B"
          onBackClick={onBackClick}
        ></AppBarGeneric>

        <div className="sm-new-appbar"></div>
      </div>
      <div className="sm-center">
        {loading ? (
          <LoaderPetroprix />
        ) : (
          <>
            {next !== "" && next !== undefined && (
              <NextCard next={next} accessToken={accessToken} />
            )}
            {last !== "" && last !== undefined && (
              <LastCard last={last} accessToken={accessToken} />
            )}
            {(next === "" || next === undefined) &&
              (last === "" || last === undefined) && (
                <div className="sm-noinfo">
                  <h3>
                    {t("sorteomensual.noinfo.nosehapodidocargarlainformacion")}
                    <br />
                    <br />
                    {t("sorteomensual.noinfo.porfavorintentelomastarde")}
                  </h3>
                </div>
              )}

            <Botones accessToken={accessToken}></Botones>
          </>
        )}
      </div>
    </>
  );
};
