// import loader_circle from "../assets/images/loader-circle-petroprix.svg";
// import loader_letter from "../assets/images/loader-letter-petroprix.svg";
import istotipo_bicolor from "../assets/images/sorteo-mensual/istotipo_bicolor.gif";
import "./LoaderPetroprix.css";

export const LoaderPetroprix = ({ type = "" }) => {
  return (
    <div className={`loaderPetroprix ${type}`}>
      <img src={istotipo_bicolor} alt="loading..." width="100" height="100" />
      {/* <img
        src={loader_circle}
        alt="circle"
        width="100"
        height="100"
        className="circle"
      />
      <img
        src={loader_letter}
        alt="p"
        width="100"
        height="100"
        className="letter"
      /> */}
    </div>
  );
};
