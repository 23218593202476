import React, { useEffect, useState } from "react";
import descuento from "../assets/images/black-friday/bf22_descuento.svg";
// import dieselImage from '../assets/images/black-friday/diesel.svg';
// import fire from '../assets/images/black-friday/fire.svg';
// import calendario from '../assets/images/black-friday/calendario.svg';
// import iconTag from "../assets/images/black-friday/icon-tag.svg";
import { useTranslation } from "react-i18next";

const FinalViewBlackFinde = ({ accessToken }) => {
  // const [diesel, setDiesel] = useState();
  // const [gasolina, setGasolina] = useState();
  // const urlEndpoint = "/campaign/promotion/blackweekend/method/getPriceGasstation";

  /*useEffect(() => {
        if (process.env.REACT_APP_API_URL) {
            fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result['cod'] === "200") {
                            //console.log(result);
                            setDiesel(result['msg']['diesel']);
                            setGasolina(result['msg']['gasolina']);
                        } else {
                            setDiesel("0,859");
                            setGasolina("0,999");
                        }
                    },
                    (error) => {
                    },
                );
        }
        else {
            setDiesel("0,859");
            setGasolina("0,999");
            //console.log('No tienes var entorno');
        }
    }, [accessToken]);*/
  const { t } = useTranslation();

  function BasesLegales() {
    window.navigation.postMessage(
      "web,https://petroprix.com/team-view/black-finde/,,,"
    );
  }

  return (
    <>
      <div className="bf-counter-text">
        {t("blackfinde.aprovechaelmayordescuentodelanoencombustible")}
      </div>
      <div className="bf-discount-img">
        <img src={descuento} alt="Black Finde logo" />
      </div>
    </>
  );
};
export default FinalViewBlackFinde;
