import React, { useEffect, useState } from "react";
import "./sorteo-mensual.css";
import { DialogPremiado } from "./DialogPremiado";
import getMonthNameByNumber from "../helpers/getNameByMonthNumber";
import { Loader } from "../components/Loader";
import { useTranslation } from "react-i18next";
import infoPremiadoIcon from "../assets/images/sorteo-mensual/info-premiado-icon.svg";
export const LastCard = ({ last, accessToken }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataPrize, setDataPrize] = useState({});
  let numberList;
  const numGanador = last["num_ganador"];
  const dataImage = last["imagen"];

  if (numGanador === "") {
    numberList = [];
  } else {
    if (numGanador.indexOf(",") != -1) {
      numberList = numGanador.split(",").map((item) => item.trim());
    } else {
      numberList = numGanador.split(" ");
    }
  }
  numberList.sort();

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  let dateFull = last["fecha_sorteo"];
  let dateFullList = [];
  let dateYear;
  let dateMonth;
  let dateMonthNumber;

  dateFullList = dateFull.split("-", 3);
  let dateFormatted =
    dateFullList[2] + "/" + dateFullList[1] + "/" + dateFullList[0];

  dateYear = dateFullList[0];
  dateMonthNumber = parseInt(dateFullList[1]);
  if (dateMonthNumber - 1 < 1) {
    dateYear = (parseInt(dateYear) - 1).toString();
    dateMonthNumber = 12;
  } else {
    dateMonthNumber -= 1;
  }
  dateMonth = dateMonthNumber.toString();

  const [myNumbers = [], setMyNumbers] = useState();
  const [loadNumbers, setLoadNumbers] = useState(false);
  const [hasNumbers, setHasNumbers] = useState(false);
  const [errors, setErrors] = useState(false);
  const { t } = useTranslation();

  const urlEndpoint = `/campaign/promotion/technologicalRaffle/method/getTechnologicalRaffleHistoryRouletteByYearAndMonth?year=${dateYear}&month=${dateMonth}`;

  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.cod === "200") {
            let list = [];
            response.msg.map((item) => {
              list.push(item.num_sorteo);
              if (numGanador.includes(item.num_sorteo)) {
                setDataPrize({
                  mes: getMonthNameByNumber(dateMonth),
                  num_sorteo: item.num_sorteo,
                });
                setIsOpen(true);
              }
            });
            setMyNumbers(list);
            setLoadNumbers(true);
            setHasNumbers(true);
          } else if (response.cod === "404") {
            setLoadNumbers(true);
          } else {
            setLoadNumbers(true);
            setErrors(true);
          }
        })
        .catch((error) => {
          setLoadNumbers(true);
          setErrors(true);
        });
    }
  }, []);

  function handleVerificacion() {
    if (last.enlace_verificacion !== null) {
      window.navigation.postMessage("web," + last.enlace_verificacion + ",,,");
    }
  }

  return (
    <div>
      <DialogPremiado
        openModal={isOpen}
        toggleModal={toggleModal}
        data={dataPrize}
      />
      <br />
      <div className="sm-card sm-mt-4">
        <div className="sm-card-header-ultimo-sorteo">
          <h5 className="">{t("sorteomensual.lastcard.h5")}</h5>
          <h1 className="sm-titulos black">
            {getMonthNameByNumber(dateMonth)}
          </h1>
        </div>
        <h2 className="sm-h2">{t("sorteomensual.nextcard.premio")}</h2>
        {dataImage !== "" ? (
          <img
            className="sm-img-premio"
            id="premio"
            alt=""
            src={`data:image/png;base64,${dataImage}`}
          />
        ) : (
          <span></span>
        )}
        <h3 className="sm-h3 regular">{last.nombre}</h3>
        <p className="sm-p regular">{last.observacion}</p>
        <div className="sm-sorteo">
          <h2 className="sm-h2 black">
            {t("sorteomensual.nextcard.h2.diadesorteo")}
          </h2>
          <span className="regular"> {dateFormatted}</span>
        </div>
        <div className="sm-desplegable">
          <h2 className="sm-h2 black">
            {t("sorteomensual.lastcard.h2.nganadores")}
          </h2>
        </div>
        <div className="sm-number-list winners">
          {numberList.map((item) => {
            return (
              <span className="sm-number-list-item" key={item}>
                {item + " "}
              </span>
            );
          })}
        </div>
        {numberList.length === 0 && (
          <span className="bold"> {t("sorteomensual.lastcard.empty")}</span>
        )}
        <div className="sm-desplegable">
          <h2 className="sm-h2 black">
            {t("sorteomensual.nextcard.h2.tusnumeros")}
          </h2>
        </div>
        <div className="sm-number-list">
          {myNumbers.map((item) => {
            return (
              <span className="sm-number-list-item" key={item}>
                {item + " "}
              </span>
            );
          })}
        </div>
        {!loadNumbers && <Loader showText={false} />}
        {loadNumbers && !hasNumbers && !errors && (
          <span className="bold">
            {" "}
            {t("sorteomensual.lastcard.errors.sinnumerosparaestesorteo")}
          </span>
        )}
        {loadNumbers && !hasNumbers && errors && (
          <span className="bold">
            {" "}
            {t("sorteomensual.nextcard.errors.nosepudocargarlainformacion")}
          </span>
        )}
        {last.enlace_verificacion !== null &&
          last.enlace_verificacion !== "" && (
            <div className="sm-sorteo-verificacion">
              <span onClick={handleVerificacion}>
                {t("sorteomensual.lastcard.verificaciondesorteo")}
              </span>
            </div>
          )}

        <div className="sm-sorteo-info-premiado">
          <div className="sm-sorteo-info-icon">
            <img
              src={infoPremiadoIcon}
              alt="info"
              className="sm-sorteo-info-icon"
            />
          </div>
          <div className="sm-sorteo-info-text">
            <span className="sm-sorteo-info-text-bold">
              {t("sorteomensual.lastcard.info.title")}
            </span>
            <span>{t("sorteomensual.lastcard.info.text")}</span>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};
