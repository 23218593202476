import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './aniversario10.css';
import { AppBarGeneric } from '../components/app_bar_generic';
import iconManguera from '../assets/images/aniversario10/manguera-icon.svg';
import iconRegalo from '../assets/images/aniversario10/regalo-icon.svg';
import iconStar from '../assets/images/aniversario10/star-icon.svg';
import iconMatricula from '../assets/images/aniversario10/matricula-icon.svg';
import iconInstagram from '../assets/images/aniversario10/instagram-icon.svg';
import iconTikTok from '../assets/images/aniversario10/tiktok-icon.svg';
import { DialogInfoAniversario10 } from './DialogInfoAniversario10';
import { DialogInfoRepostaje } from './DialogInfoRepostaje';

export const Aniversario10 = () => {
  const [searchParams] = useSearchParams();
  let accessToken = searchParams.get('token');
  if (!accessToken) {
    accessToken = '';
  }

  const urlEndpoint = '/campaign/method/getTotalInfoAnniversary';
  const [fase, setFase] = useState('0');
  const [trofeo, setTrofeo] = useState(false);
  const [infoAniversario, setInfoAniversario] = useState({});

  const [fechaInicioPromo, setFechaInicioPromo] = useState('10 de Junio');
  const [fechaFinPromo, setFechaFinPromo] = useState('31 de Julio');
  const [fechaFinFase0, setFechaFinFase0] = useState('20 de Junio');
  const [fechaInicioFase1, setFechaInicioFase1] = useState('21 de Junio');
  const [litros, setLitros] = useState(0);
  const [litrosDecimales, setLitrosDecimales] = useState(0);
  const [litrosVenta, setLitrosVenta] = useState(0);
  const [litrosVentaDecimales, setLitrosVentaDecimales] = useState(0);

  const [isOpenModalAniversario10, setIsOpenModalAniversario10] =
    useState(false);
  const [isOpenModalRepostaje, setIsOpenModalRepostaje] = useState(false);

  let dataModal = {
    fechaInicioPromo: fechaInicioPromo,
    fechaFinPromo: fechaFinPromo,
    fechaFinFase0: fechaFinFase0,
    fechaInicioFase1: fechaInicioFase1,
  };

  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result['cod'] === '200') {
              setFase(result['msg']['fase']);

              if (result['msg']['trofeo'] === '1') {
                setTrofeo(true);
              }

              setInfoAniversario(result['msg']);

              if (result['msg']['mostrar'] === 1) {
                setIsOpenModalRepostaje(true);
              }

              if (result['msg']['fecha_inicio_promocion'] !== '2023-06-10') {
                let fecha_inicio_promo =
                  result['msg']['fecha_inicio_promocion'].split('-');
                setFechaInicioPromo(
                  fecha_inicio_promo[2] + ' de ' + getMes(fecha_inicio_promo[1])
                );
              }

              if (result['msg']['fecha_final_promocion'] !== '2023-07-31') {
                let fecha_fin_promo =
                  result['msg']['fecha_final_promocion'].split('-');
                setFechaFinPromo(
                  fecha_fin_promo[2] + ' de ' + getMes(fecha_fin_promo[1])
                );
              }

              if (result['msg']['fecha_final_fase_0'] !== '2023-06-20') {
                let fecha_final_fase_0 =
                  result['msg']['fecha_final_fase_0'].split('-');
                setFechaFinFase0(
                  fecha_final_fase_0[2] + ' de ' + getMes(fecha_final_fase_0[1])
                );
              }

              if (result['msg']['fecha_inicio_fase_1'] !== '2023-06-21') {
                let fecha_inicio_fase_1 =
                  result['msg']['fecha_inicio_fase_1'].split('-');
                setFechaInicioFase1(
                  fecha_inicio_fase_1[2] +
                    ' de ' +
                    getMes(fecha_inicio_fase_1[1])
                );
              }

              if (result['msg']['litros'] !== '0') {
                let litros = result['msg']['litros'].split('.');
                setLitros(litros[0]);
                setLitrosDecimales(litros[1]);
              }

              if (result['msg']['litros_venta'] !== '0') {
                let litros_venta = result['msg']['litros_venta'].split('.');
                setLitrosVenta(litros_venta[0]);
                setLitrosVentaDecimales(litros_venta[1]);
              }
            }
          },
          (error) => {}
        );
    } else {
      console.log('No tienes var entorno');
    }
  }, [accessToken]);

  const getMes = (mes) => {
    switch (mes) {
      case '01':
        return 'Enero';
      case '02':
        return 'Febrero';
      case '03':
        return 'Marzo';
      case '04':
        return 'Abril';
      case '05':
        return 'Mayo';
      case '06':
        return 'Junio';
      case '07':
        return 'Julio';
      case '08':
        return 'Agosto';
      case '09':
        return 'Septiembre';
      case '10':
        return 'Octubre';
      case '11':
        return 'Noviembre';
      case '12':
        return 'Diciembre';
      default:
        return '';
    }
  };

  function onBackClick() {
    window.navigation.postMessage('native,,,pop,');
  }

  function toggleModalAniversario10() {
    setIsOpenModalAniversario10(!isOpenModalAniversario10);
  }

  function toggleModalRepostaje() {
    setIsOpenModalRepostaje(!isOpenModalRepostaje);
  }

  // function verVehiculos() {
  //   window.navigation.postMessage("native,,,carsdata,");
  // }

  function navigateInstagram() {
    window.navigation.postMessage(
      'web,https://www.instagram.com/petroprix_es/,,,'
    );
  }

  function navigateTikTok() {
    window.navigation.postMessage('web,https://www.tiktok.com/@petroprix,,,');
  }

  return (
    <>
      <div className='appbar-aniversario-10-parent'>
        <div className='appbar-aniversario-10'>
          <AppBarGeneric
            title='10º Aniversario'
            backgroundColor='#2B363B'
            textColor='#ffffff'
            onBackClick={onBackClick}
          ></AppBarGeneric>
        </div>
      </div>
      <div className='anv-background'>
        <div className='anv-content'>
          <div
            className={
              trofeo && fase === '0' ? 'anv-header-trofeo' : 'anv-header'
            }
          >
            {fase === '0' ? ( // Fase 0
              !trofeo ? ( // Si no tiene trofeo
                <>
                  <div className='anv-title-text bold'>Has conseguido</div>
                  <div className='anv-litros regular black'>
                    {litros}
                    <span className='anv-litros-decimals black'>
                      ,
                      {litrosDecimales != null && litrosDecimales !== 0
                        ? litrosDecimales
                        : '00'}{' '}
                      L
                    </span>
                  </div>
                  <div className='anv-footer-text bold'>con descuento</div>
                </>
              ) : (
                // Si tiene trofeo
                <>
                  <div className='anv-title-trofeo'>
                    <div className='anv-title-text bold'>
                      ¡Logro desbloqueado!
                    </div>
                    <div className='anv-trofeo-text regular'>
                      Has alcanzado el límite de{' '}
                      {infoAniversario.total_litros_promo} litros con descuento
                      que podrás usar del {fechaInicioFase1} al {fechaFinPromo}.
                    </div>
                    <div className='anv-footer-text bold'>
                      ¡Gracias por elegirnos!
                    </div>
                  </div>
                </>
              )
            ) : (
              // Fase 1
              <>
                <div className='anv-title-text bold'>Te quedan por gastar</div>
                <div className='anv-litros regular black'>
                  {litros}
                  <span className='anv-litros-decimals black'>
                    ,
                    {litrosDecimales != null && litrosDecimales !== 0
                      ? litrosDecimales
                      : '00'}{' '}
                    L
                  </span>
                </div>
                <div className='anv-footer-text bold'>con descuento</div>
              </>
            )}
            {fase === '0' && ( // Fase 0
              <button
                onClick={toggleModalAniversario10}
                className='anv-detalles-btn black'
              >
                Detalles de la promoción
              </button>
            )}
          </div>

          <div className='anv-promo-info'>
            <span className='anv-info-header'>
              <span className='anv-info-title black'>
                {fase === 0
                  ? '¿Qué hemos preparado?'
                  : '¿Cómo canjear los litros?'}
              </span>
            </span>
            <ul className='anv-info-steps'>
              {fase === '0' ? ( // Fase 0}
                <>
                  <span className='anv-step-item'>
                    <span className='anv-icon'>
                      <img src={iconManguera} alt='' />
                    </span>
                    <li className='anv-step regular'>
                      Los litros que repostes en el aniversario tendrán 10
                      cént./L de descuento en tus repostajes del{' '}
                      {fechaInicioFase1} al {fechaFinPromo}.
                    </li>
                  </span>
                  <span className='anv-step-item'>
                    <span className='anv-icon'>
                      <img src={iconStar} alt='' />
                    </span>
                    <li className='anv-step regular'>
                      El número de ticket de tu primer repostaje es tu
                      participación diaria para ganar un año de gasolina de los
                      10 que sorteamos.
                    </li>
                  </span>
                  <span className='anv-step-item'>
                    <span className='anv-icon'>
                      <img src={iconRegalo} alt='' />
                    </span>
                    <li className='anv-step regular'>
                      Si nos sigues en Instagram podrás ganar viajes,
                      smartwatch, cámaras y muchos más premios.
                    </li>
                  </span>
                </>
              ) : (
                // Fase 1
                <>
                  <span className='anv-step-item'>
                    <span className='anv-icon'>
                      <img src={iconManguera} alt='' />
                    </span>
                    <li className='anv-step regular'>
                      Los litros acumulados durante la promoción los podrás
                      canjear del {fechaInicioFase1} al {fechaFinPromo}.
                    </li>
                  </span>
                  <span className='anv-step-item'>
                    <span className='anv-icon'>
                      <img
                        src={iconMatricula}
                        alt=''
                        style={{ marginLeft: '-1px' }}
                      />
                    </span>
                    <li className='anv-step regular'>
                      La cámara captará la matrícula de tu vehículo y aplicará
                      el descuento automáticamente en tu repostaje.
                    </li>
                  </span>
                </>
              )}
            </ul>
            {fase === '0' ? ( // Fase 0
              <div className='anv-info-footer black'>
                ¡Los ganadores de los sorteos se anunciarán por Instagram!
              </div>
            ) : (
              // Fase 1
              <div className='anv-info-footer small bold'>
                <p>
                  <b>
                    Si necesitas repostar más litros de los que te quedan con
                    descuento, ten presente que el repostaje se detendrá en esa
                    cantidad restante. Una vez hayas consumido el descuento,
                    solo tienes que volver a repostar de la forma habitual.
                  </b>
                </p>
                <p>
                  ¡Aprovecha el descuento conseguido durante el aniversario!{' '}
                </p>
              </div>
            )}
          </div>

          <div className='anv-promo-info' style={{ marginBottom: '8vh' }}>
            <div className='anv-info-header center'>
              <span className='anv-info-title black'>
                Síguenos para no perderte nada
              </span>
            </div>
            <div className='anv-social-media'>
              <button
                onClick={navigateInstagram}
                className='anv-btn-social bold'
              >
                <img className='anv-logo-social' src={iconInstagram} alt='' />{' '}
                Instagram
              </button>
              <button onClick={navigateTikTok} className='anv-btn-social bold'>
                <img className='anv-logo-social' src={iconTikTok} alt='' /> Tik
                Tok
              </button>
            </div>
          </div>
        </div>
      </div>

      <DialogInfoAniversario10
        isOpen={isOpenModalAniversario10}
        toggleModal={toggleModalAniversario10}
        data={dataModal}
      ></DialogInfoAniversario10>
      <DialogInfoRepostaje
        isOpen={isOpenModalRepostaje}
        toggleModal={toggleModalRepostaje}
        litrosVenta={litrosVenta}
        litrosVentaDecimales={litrosVentaDecimales}
      ></DialogInfoRepostaje>
    </>
  );
};
