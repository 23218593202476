import React from "react";
import { useState } from "react";
import { AppBarGeneric } from "../components/app_bar_generic";
import logoReyesMagos from "../assets/images/reyes-magos/logo.svg";
import bg from "../assets/images/reyes-magos/bg.svg";
import giftIcon from "../assets/images/reyes-magos/gift-icon.svg";
import starIcon from "../assets/images/reyes-magos/star-icon.svg";
import bellsIcon from "../assets/images/reyes-magos/bells-icon.svg";
import { DialogInfoReyesMagos } from "./DialogInfoReyesMagos";
import "./reyes-magos.css";
import { useTranslation } from "react-i18next";

export const ReyesMagos = ({ accessToken }) => {
  // const [gasolinera, setGasolinera] = useState();
  // const urlEndpoint = "/gasstation/method/getPrimaryGasstation";
  // const gasStationCanarias = ['84', '85', '86', '98', '104'];
  // const difference = +new Date(`11/18/2021`) - +new Date();

  const { t } = useTranslation();

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }

  const [isOpenReyesMagos, setIsOpenReyesMagos] = useState(false);
  function toggleModalReyesMagos() {
    setIsOpenReyesMagos(!isOpenReyesMagos);
  }

  function verVehiculos() {
    window.navigation.postMessage("native,,,carsdata,");
  }

  function BasesLegales() {
    window.navigation.postMessage(
      "web,https://petroprix.com/team-view/dia-de-reyes/,,,"
    );
  }

  return (
    <>
      <div className="appbar-reyes-magos-parent">
        <div className="appbar-reyes-magos">
          <AppBarGeneric
            title={t("reyesmagos.header")}
            backgroundColor="#ffffff"
            onBackClick={onBackClick}
          ></AppBarGeneric>
        </div>
      </div>
      <div className="rm-header-logo">
        <img src={bg} className="rm-snow" alt="Black Finde logo" />
      </div>
      <div className="rm-background">
        <div className="rm-content">
          <div>
            <div className="rm-logo">
              <img
                src={logoReyesMagos}
                className="rm-logo"
                alt="Reyes Magos de Petroprix"
              />
            </div>
          </div>
          <div className="rm-discount">
            <div className="rm-discount-info">
              <span className="rm-icon">
                <img src={giftIcon} alt="" />
              </span>
              <span className="rm-discount-info-text rm-strong">
                {t("reyesmagos.disfrutadeldescuento")}
              </span>
              <span
                className="rm-discount-info-text rm-red"
                onClick={BasesLegales}
              >
                {t("reyesmagos.verbases")}
              </span>
            </div>
            <div className="rm-discount-price">
              <span className="rm-price-legend">
                {t("reyesmagos.ahorra5centimosporlitro")}
              </span>
              <span className="rm-price-cents">
                <span className="rm-strong">0,05</span>
                <span className="rm-price-small"> €/L</span>
              </span>
            </div>
          </div>
          <div className="rm-dates-info">
            <span className="rm-icon">
              <img src={starIcon} alt="" />
            </span>
            <span className="rm-info-text rm-strong">
              {t("reyesmagos.cuando")}
            </span>
            <span className="rm-info-text">
              <span className="rm-red rm-strong">
                {t("reyesmagos.seisdeenero")}
              </span>{" "}
              {t("reyesmagos.de2022")}
            </span>
          </div>
          <div className="rm-promo-info">
            <span className="rm-info-header">
              <span className="rm-icon">
                <img src={bellsIcon} alt="" />
              </span>
              <span className="rm-strong">
                {t("reyesmagos.steps.sigueestospasosparaconseguirtu")}
                <br />
                {t("reyesmagos.steps.descuento")}
              </span>
            </span>
            <ul className="rm-info-steps">
              <span className="rm-step-number">1</span>
              <li className="rm-step">
                {t("reyesmagos.steps.verificaquetienestumatricula")}{" "}
                <span className="rm-strong">
                  {t("reyesmagos.steps.registradaenlaaplicacion")}
                </span>
                .
                {t(
                  "reyesmagos.steps.sidesconocessitumatriculaestaregistradaonopuedes"
                )}{" "}
                <span className="rm-link" onClick={verVehiculos}>
                  {t("reyesmagos.steps.comprobarloaqui")}.
                </span>
              </li>
              <span className="rm-step-number">2</span>
              <li className="rm-step">
                {t("reyesmagos.steps.cuandoestesenelsurtidorrecuerda")}{" "}
                <span className="rm-strong">
                  {t("reyesmagos.steps.identificartecomousuario")}
                </span>
                .
                <br />
                <span className="rm-link" onClick={toggleModalReyesMagos}>
                  {t("reyesmagos.steps.comomeidentificocomousuario")}
                </span>
              </li>
              <span className="rm-step-number">3</span>
              <li className="rm-step">
                {t("reyesmagos.steps.repostaconeldescuentazo")}. Easy.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <DialogInfoReyesMagos
        isOpen={isOpenReyesMagos}
        toggleModal={toggleModalReyesMagos}
      ></DialogInfoReyesMagos>
    </>
  );
};
