import React from "react";
import "./loader.css";

export const Loader = ({ showText, textLoading = "" }) => {
  return (
    <div className="loader-box">
      {showText && <span className="loader-text">{textLoading}</span>}
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
