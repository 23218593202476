import Modal from "react-modal";
import "./sorteo-mensual.css";
import { useState } from "react";
import { isIOS } from "react-device-detect";

import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");

export const DialogParticipar = ({ isOpen, toggleModal }) => {
  var pageReinicializer = 0;
  const [page, setpage] = useState(pageReinicializer);
  const { t } = useTranslation();

  function nextPage() {
    setpage(page + 1);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="sm-mymodal"
      overlayClassName="sm-myoverlay"
      closeTimeoutMS={200}
    >
      {page === 0 ? (
        <div>
          <div className="sm-titulo-alert-parent">
            <span className="sm-titulo-alert black">
              {t("sorteomensual.buttons.dialogoparticipar.comofunciona")}{" "}
              <br></br>
              {t("sorteomensual.buttons.dialogoparticipar.elsorteo")}
            </span>
          </div>
          <div className="sm-texto-contenido-alert-participar-parent sm-margin-1">
            <span className="regular">
              {t(
                "sorteomensual.buttons.dialogoparticipar.cadavezquetiresenlaruletay"
              )}
            </span>
            <span className="black">
              {" "}
              {t(
                "sorteomensual.buttons.dialogoparticipar.noobtengaspremioteasignaremosunnumero"
              )}
            </span>
            <span className="regular">
              {" "}
              {t(
                "sorteomensual.buttons.dialogoparticipar.queseravalidounicamenteparaelsorteomensualdeesemes"
              )}
            </span>
            <br />
            <br />
            <span className="black">
              {t(
                "sorteomensual.buttons.dialogoparticipar.losnumerossonacumulables"
              )}
            </span>
            <span className="regular">
              {t(
                "sorteomensual.buttons.dialogoparticipar.porloquepuedesparticiparconvariosalavez"
              )}{" "}
            </span>
          </div>
          {isIOS && (
            <div className="sm-texto-contenido-legal-apple">
              <span className="regular">
                {t("sorteomensual.buttons.dialogoparticipar.apple")}
              </span>
            </div>
          )}
          <div className="sm-boton-como-funciona-alert-parent">
            <button onClick={nextPage} className="sm-boton bold">
              {t("sorteomensual.buttons.dialogoparticipar.siguiente")}
            </button>
          </div>
        </div>
      ) : page === 1 ? (
        <div>
          <div className="sm-titulo-alert-parent">
            <span className="sm-titulo-alert black">
              {t("sorteomensual.buttons.dialogoparticipar.comofunciona")}{" "}
              <br></br>
              {t("sorteomensual.buttons.dialogoparticipar.elsorteo")}
            </span>
          </div>
          <div className="sm-texto-contenido-alert-participar-parent sm-margin-1">
            <span className="regular">
              {t(
                "sorteomensual.buttons.dialogoparticipar.entreelprimeryelsegundodialaborabledecadamesrealizaremosunsorteocertificado"
              )}
            </span>
            <span className="black">
              {" "}
              {t(
                "sorteomensual.buttons.dialogoparticipar.entretodoslosnumerosparticipantes"
              )}
            </span>
            <span className="regular">
              {t(
                "sorteomensual.buttons.dialogoparticipar.delosqueobtendremoslosganadoresdeformaaleatoria"
              )}
            </span>
          </div>
          <div className="sm-boton-como-funciona-alert-parent">
            <button onClick={nextPage} className="sm-boton bold">
              {t("sorteomensual.buttons.dialogoparticipar.siguiente")}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="sm-titulo-alert-parent">
            <span className="sm-titulo-alert black">
              {t("sorteomensual.buttons.dialogoparticipar.comofunciona")}{" "}
              <br></br>
              {t("sorteomensual.buttons.dialogoparticipar.elsorteo")}
            </span>
          </div>
          <div className="sm-texto-contenido-alert-participar-parent sm-margin-1">
            <span className="regular">
              {t(
                "sorteomensual.buttons.dialogoparticipar.unavezobtenidoslosganadoresqueserantantoscomopremiossorteemosesemes"
              )}
            </span>
            <span className="black">
              {" "}
              {t(
                "sorteomensual.buttons.dialogoparticipar.compartiremoselcertificadodeverificacion"
              )}
            </span>
            <span className="regular">
              {" "}
              {t(
                "sorteomensual.buttons.dialogoparticipar.ylosnumerosganadorestantoenla"
              )}{" "}
            </span>
            <span className="black"> app </span>
            <span className="regular">
              {t("sorteomensual.buttons.dialogoparticipar.comoennuestras")}
            </span>
            <span className="black">
              {" "}
              {t("sorteomensual.buttons.dialogoparticipar.redessociales")}
            </span>
          </div>
          <div className="sm-boton-como-funciona-alert-parent">
            <button onClick={toggleModal} className="sm-boton bold">
              {t("sorteomensual.buttons.dialogoparticipar.aceptar")}
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};
