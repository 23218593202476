import React from "react";
import { useState, useEffect } from "react";
import { AppBarGeneric } from "../components/app_bar_generic";
import logoBlackFinde from "../assets/images/black-friday/blanfinde22_logo.svg";
import iconCalendar from "../assets/images/black-friday/icon-calendar.svg";
import iconImportant from "../assets/images/black-friday/icon-important.svg";
import FinalViewBlackFinde from "./FinalViewBlackFinde";
import { DialogInfoBlackFinde } from "./DialogInfoBlackFinde";
import { useTranslation } from "react-i18next";

const Timer = ({ accessToken }) => {
  const [gasolinera, setGasolinera] = useState();
  const { t } = useTranslation();
  const urlEndpoint = "/gasstation/method/getPrimaryGasstation";
  const gasStationCanarias = [
    "84",
    "85",
    "86",
    "98",
    "104",
    "116",
    "118",
    "122",
    "144",
  ];
  const difference = +new Date(`11/25/2022`) - +new Date();
  const calculateTimeLeft = () => {
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  let timerComponents = [];

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result["cod"] === "200") {
              setGasolinera(result["msg"]["0"]["id_gas"]);
            } else {
              setGasolinera("2");
            }
          },
          (error) => {}
        );
    } else {
      setGasolinera("2");
      //console.log('No tienes var entorno');
    }
  }, [accessToken]);

  Object.keys(timeLeft).forEach((interval) => {
    switch (interval) {
      case "hours":
        timerComponents.push(
          <div className="bf-clock-item" key={timerComponents.length + 1}>
            <span>
              <span className="bf-clock-number">
                {timeLeft[interval] < 10 && "0"}
                {timeLeft[interval]}
              </span>
              <span className="bf-clock-text">
                {" "}
                {t("blackfinde.reloj.horas")}
              </span>
            </span>
          </div>
        );
        break;
      case "minutes":
        timerComponents.push(
          <div className="bf-clock-item" key={timerComponents.length + 2}>
            <span>
              <span className="bf-clock-number">
                {timeLeft[interval] < 10 && "0"}
                {timeLeft[interval]}
              </span>
              <span className="bf-clock-text">
                {" "}
                {t("blackfinde.reloj.minutos")}
              </span>
            </span>
          </div>
        );
        break;
      case "seconds":
        timerComponents.push(
          <div className="bf-clock-item" key={timerComponents.length + 3}>
            <span>
              <span className="bf-clock-number">
                {timeLeft[interval] < 10 && "0"}
                {timeLeft[interval]}
              </span>
              <span className="bf-clock-text">
                {" "}
                {t("blackfinde.reloj.segundos")}
              </span>
            </span>
          </div>
        );
        break;
      default:
        break;
    }
  });

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }

  const [isOpenBlackFinde, setIsOpenBlackFinde] = useState(false);
  function toggleModalBlackFinde() {
    setIsOpenBlackFinde(!isOpenBlackFinde);
  }

  function verVehiculos() {
    window.navigation.postMessage("native,,,carsdata,");
  }

  return (
    <>
      <div className="appbar-black-finde-parent">
        <div className="appbar-black-finde">
          <AppBarGeneric
            title={t("blackfinde.header")}
            backgroundColor="#000000"
            textColor="#ffffff"
            onBackClick={onBackClick}
          ></AppBarGeneric>
        </div>
      </div>
      <div className="bf-background">
        <div className="bf-content">
          <div className="bf-logo">
            <img
              src={logoBlackFinde}
              className="bf-logo"
              alt="Black Finde logo"
            />
          </div>
          {timerComponents.length ? (
            <div>
              <div className="bf-counter-text">
                {t(
                  "blackfinde.preparateparaelmayordescuentodelanoencombustible"
                )}
              </div>
              <div className="bf-counter">
                {timerComponents.length ? (
                  timerComponents
                ) : (
                  <span>{t("blackfinde.recarguelapromocion")}</span>
                )}
              </div>
            </div>
          ) : (
            <FinalViewBlackFinde accessToken={accessToken} />
          )}
          <div className="bf-dates-info">
            <span className="bf-icon">
              <img src={iconCalendar} alt="" />
            </span>
            <span className="bf-info-text bf-strong">
              {t("blackfinde.cuandoeselblackfinde")}
            </span>
            {gasStationCanarias.includes(gasolinera) ? (
              <span className="bf-info-text">
                <span className="bf-red bf-strong">
                  25, 26 {t("blackfinde.y")} 27{" "}
                </span>{" "}
                {t("blackfinde.denoviembre")}
              </span>
            ) : (
              <span className="bf-info-text">
                <span className="bf-red bf-strong">
                  25, 26 {t("blackfinde.y")} 27{" "}
                </span>{" "}
                {t("blackfinde.denoviembre")}
              </span>
            )}
          </div>
          <div className="bf-promo-info">
            <span className="bf-info-header">
              <span className="bf-icon">
                <img src={iconImportant} alt="" />
              </span>
              <span className="bf-strong">
                {t("blackfinde.steps.sigueestospasosparaconseguirtu")}
                <br />
                {t("blackfinde.steps.descuento")}
              </span>
            </span>
            <ul className="bf-info-steps">
              <span className="bf-step-number">1</span>
              <li className="bf-step">
                {t("blackfinde.steps.verificaquetienestumatricula")}{" "}
                <span className="bf-strong">
                  {t("blackfinde.steps.registradaenlaaplicacion")}
                </span>
                .{" "}
                {t(
                  "blackfinde.steps.sidesconocessitumatriculaestaregistradaonopuedes"
                )}{" "}
                <span className="bf-link" onClick={verVehiculos}>
                  {t("blackfinde.steps.comprobarloaqui")}.
                </span>
              </li>
              <span className="bf-step-number">2</span>
              <li className="bf-step">
                {t("blackfinde.steps.cuandoestesenelsurtidorrecuerda")}{" "}
                <span className="bf-strong">
                  {t("blackfinde.steps.identificartecomousuario")}
                </span>
                .
                <br />
                <span className="bf-link" onClick={toggleModalBlackFinde}>
                  {t("blackfinde.steps.comomeidentificocomousuario")}
                </span>
              </li>
              <span className="bf-step-number">3</span>
              <li className="bf-step">
                {t("blackfinde.steps.escogetuopcionderepostajey")}{" "}
                <span className="bf-strong">
                  {t("blackfinde.steps.eldescuentoseaplicaradeformaautomatica")}
                  .
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <DialogInfoBlackFinde
        isOpen={isOpenBlackFinde}
        toggleModal={toggleModalBlackFinde}
      ></DialogInfoBlackFinde>
    </>
  );
};

export default Timer;
