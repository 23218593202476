import React, { useLayoutEffect, useRef, useState } from "react";
import { HelloAutoAppBar } from "../components/app_bar_helloauto";
import { useNavigate } from "react-router-dom";
import hello from "../assets/images/tirada/helloauto-white.svg";
import pump from "../assets/images/tirada/fuel-pump.png";
import "./tirada.css";
import { useTranslation } from "react-i18next";

export const TiradaGratisHelloAuto1 = (props) => {
  const [box, setbox] = useState({});

  const history = useNavigate();
  const pTag = useRef();
  let data = props.url;
  const { t } = useTranslation();

  function handleClickNext() {
    history("/tirada2", {
      state: {
        data: data,
        os: props.os,
        width: box.width,
        height: box.height,
      },
    });
  }

  useLayoutEffect(() => {
    setbox(pTag.current.getBoundingClientRect());
  }, []);

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }

  return (
    <>
      <HelloAutoAppBar
        title="Promo Hello Auto"
        onBackClick={onBackClick}
        color="#018bed"
      ></HelloAutoAppBar>
      <div className="th-parent-ha" ref={pTag}>
        <div className="th-div-rounded-ha-big">
          <img src={hello} className="th-img-hello-title" alt="Gasolina" />
          <div className="th-div-bienvenido">
            <span className="silka-bold th-bienvenido">
              {t(
                "promotirada.tirada1.bienvenidoalarevoluciondelossegurosdecoche"
              )}
            </span>
          </div>
        </div>
        <div className="th-div-rounded-ha-small"></div>
        <div className="th-subtitulo-bajo-rounded silka-bold">{t("")}</div>
        <div className="th-div-icon-on-parent">
          <img src={pump} className="th-img-hello-pump" alt="Gasolina" />
          <div className="th-div-icon-on">
            <div className="th-text-tirada1-container">
              <span className="silka-black th-treintae">50€</span>
              <br></br>

              <span className="silka-regular th-texto-containerblue">
                {t("promotirada.tirada1.encombustible")}{" "}
              </span>
              <br></br>
              <span className="silka-regular th-texto-containerblue">
                {" "}
                {t("promotirada.tirada1.engasolineras")}{" "}
              </span>
              <span className="silka-bold th-texto-containerblue">
                {" "}
                PETROPRIX{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="th-row-button-ha-parent">
          <div className="th-row-buttons-ha">
            <div className="th-boton-siguiente-ha3 silka-bold">
              {t("promotirada.tirada1.atras")}
            </div>
            <div
              className="th-boton-siguiente-ha1 silka-bold"
              onClick={handleClickNext}
            >
              {t("sorteomensual.buttons.dialogoparticipar.siguiente")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
