import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TiradaGratisHelloAuto1 } from "./tirada1";
import "./tirada.css";
import { useTranslation } from "react-i18next";

export const PromocionTirada = () => {
  const [url, setUrl] = useState("");
  const [os, setOs] = useState("");
  const [code, setCode] = useState("2");

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  let accessToken = searchParams.get("token");

  const urlEndpoint =
    "/campaign/promotion/quoteHelloAuto/method/getStatusCampaignHA";

  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      fetch(process.env.REACT_APP_API_URL + urlEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result["cod"] === "200") {
              setUrl(result["msg"]["url"]);
              setOs(result["msg"]["os"]);
              setCode("3");
            } else {
              setCode("4");
            }
          },
          (error) => {
            //console.log("Ha ocurrido un error")
          }
        );
    }
  }, [accessToken]);

  return (
    <>
      {code === "2" ? (
        <div className="th-pantalla">
          <div className="bold th-pantalla-text">
            {t("promotirada.cargando")}...
          </div>
        </div>
      ) : code === "4" ? (
        <div className="th-pantalla">
          <div className="bold th-pantalla-text">
            {t("promotirada.haocurridounerror")}
          </div>
        </div>
      ) : (
        <TiradaGratisHelloAuto1 url={url} os={os}></TiradaGratisHelloAuto1>
      )}
    </>
  );
};
