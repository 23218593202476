import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "es",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      es: {
        translation: {
          sorteomensual: {
            header: "Sorteo mensual",
            noinfo: {
              nosehapodidocargarlainformacion:
                "No se ha podido cargar la información",
              porfavorintentelomastarde: "Por favor inténtelo más tarde.",
            },
            nextcard: {
              h5: "PRÓXIMO SORTEO",
              premio: "PREMIO",
              h2: { diadesorteo: "DIA DE SORTEO", tusnumeros: "TUS NÚMEROS" },
              errors: {
                aunnotienesnumeros: "Aún no tienes números",
                nosepudocargarlainformacion: "No se pudo cargar la información",
              },
            },
            lastcard: {
              h5: "ÚLTIMO SORTEO",
              h2: { nganadores: "Nº GANADORES" },
              empty: "Aún no hay premiados",
              errors: {
                sinnumerosparaestesorteo: "Sin números para este sorteo",
              },
              verificaciondesorteo: "Verificación de sorteo",
              info: {
                title: "En caso de que tu número resulte premiado,",
                text: " nos comunicaremos contigo al correo electrónico asociado a tu cuenta Petroprix para coordinar la entrega del premio.",
              },
            },
            buttons: {
              historial: "Historial",
              sorteos: "sorteos",
              como: "Cómo",
              participar: "participar",
              bases: "Bases",
              legales: "legales",
              dialogoparticipar: {
                comofunciona: "¿Cómo funciona",
                elsorteo: "el sorteo?",
                cadavezquetiresenlaruletay: "Cada vez que tires en la ruleta y",
                noobtengaspremioteasignaremosunnumero:
                  "no obtengas premio te asignaremos un número",
                queseravalidounicamenteparaelsorteomensualdeesemes:
                  "que será válido únicamente para el sorteo mensual de ese mes.",
                losnumerossonacumulables: "Los números son acumulables,",
                porloquepuedesparticiparconvariosalavez:
                  " por lo que puedes participar con varios a la vez.",
                apple: "Apple no patrocina ni guarda relación con este sorteo",
                entreelprimeryelsegundodialaborabledecadamesrealizaremosunsorteocertificado:
                  "Entre el primer y el segundo día laborable de cada mes, realizaremos un sorteo certificado",
                entretodoslosnumerosparticipantes:
                  "entre todos los números participantes",
                delosqueobtendremoslosganadoresdeformaaleatoria:
                  ", de los que obtendremos los ganadores de forma aleatoria.",
                siguiente: "Siguiente",
                unavezobtenidoslosganadoresqueserantantoscomopremiossorteemosesemes:
                  "Una vez obtenidos los ganadores, que serán tantos como premios sorteemos ese mes,",
                compartiremoselcertificadodeverificacion:
                  "compartiremos el certificado de verificación",
                ylosnumerosganadorestantoenla:
                  "y los números ganadores tanto en la",
                comoennuestras: ", como en nuestras",
                redessociales: "redes sociales.",
                aceptar: "Aceptar",
              },
              dialogopremiado: {
                premioobtenido: "¡Premio obtenido!",
                tunumero: "Tu número",
                hasido: "ha sido",
                premiadoenel: "premiado en el",
                sorteomensualde: "sorteo mensual de",
                nospondremosencontactocontigomuyprontoparahacertellegartupremio:
                  "Nos pondremos en contacto contigo muy pronto para hacerte llegar\ntu premio",
              },
              history: {
                loading: "Cargando sorteos del año",
                errors: { nohaysorteosesteano: "No hay sorteos este año" },
              },
            },
          },
          promotirada: {
            cargando: "Cargando",
            haocurridounerror: "Ha ocurrido un error",
            tirada1: {
              bienvenidoalarevoluciondelossegurosdecoche:
                "Bienvenido a la revolución de los seguros de coche",
              encombustible: "EN COMBUSTIBLE",
              engasolineras: "EN GASOLINERAS",
              atras: "Atrás",
            },
            tirada2: {
              soloporpasartusegurodecocheahelloautotellevas:
                "Sólo por pasar tu seguro de coche a Hello Auto, te llevas...",
              llevateunatirada: "LLÉVATE 1 TIRADA",
              enlaruleta: "en la ruleta",
              soloporcalcular: "sólo por calcular",
              elpreciodetupoliza: "el precio de tu póliza",
              gratis: { minusc: "Gratis", mayusc: "GRATIS" },
            },
            tirada3: {
              eslaprimeraaseguradoradondelosbuenosconductores:
                "Es la primera aseguradora donde los buenos conductores",
              ahorrancadaano: "ahorran cada año.",
              ademasrecibiras: "Además recibirás",
              contuseguro: "CON TU SEGURO",
              quees: "¿Qué es",
            },
            tirada4: {
              button: {
                camara: "Cámara",
                asistente: "Assistente",
                radares: "Radares",
              },
              eselcopilotointeligenteconelque:
                "Es el copiloto inteligente con el que",
              podras: "podrás:",
              scroll: {
                grabartodo: "Grabar todo",
                loqueocurra: "lo que ocurra",
                mientrasconduces: "mientras conduces.",
                conocerla: "Conocer la",
                velocidadmaximadecadavia: "velocidad máxima de cada vía",
                yalaquecirculasencadamomento:
                  "y a la que circulas en cada momento.",
                tener: "Tener",
                serviciodeemergencia: "servicio de emergencia",
                entodomomentoy: "en todo momento y",
                llamadaautomaticadeemergencia:
                  "llamada automática de emergencia.",
                detectarlosradaresantesquenadie:
                  "Detectar los radares antes que nadie.",
                localizartucocheentodomomento:
                  "Localizar tu coche en todo momento.",
                teneraccesoatodaslasrutasquehasrealizado:
                  "Tener acceso a todas las rutas que has realizado",
                yconocerdatosdetodasellas: "y conocer datos de todas ellas.",
              },
            },
            tirada5: {
              cuantopuedo: "¿Cuánto puedo",
              ahorrar: "ahorrar",
              con: "con",
              sabemosque: "Sabemos que",
              tegustaahorrar: "te gusta ahorrar",
              yque: "y que",
              conducesmejorquenadie: "conduces mejor que nadie.",
              enhelloautopuedestraertusegurodecochedesde:
                "En Hello Auto puedes traer tu seguro de coche desde:",
              calculaahoratuseguroydescubrecualestuprecio:
                "Calcula ahora tu seguro y descubre cuál es tu precio.",
              calcular: "Calcular",
              almes: "al mes",
            },
          },
          reyesmagos: {
            header: "Reyes Magos",
            disfrutadeldescuento: "¡Disfruta del descuento!",
            verbases: "Ver bases",
            ahorra5centimosporlitro: "Ahorra 5 céntimos por litro",
            cuando: "Cuándo",
            seisdeenero: "6 de enero",
            de2022: "de 2022",
            steps: {
              sigueestospasosparaconseguirtu:
                "Sigue estos pasos para conseguir tu",
              descuento: "descuento",
              verificaquetienestumatricula: "Verifica que tienes tu matrícula",
              registradaenlaaplicacion: "registrada en la aplicación",
              sidesconocessitumatriculaestaregistradaonopuedes:
                "Si desconoces si tu matrícula está registrada o no, puedes",
              comprobarloaqui: "comprobarlo aquí",
              cuandoestesenelsurtidorrecuerda:
                "Cuando estés en el surtidor, recuerda",
              identificartecomousuario: "identificarte como usuario",
              epostaconeldescuentazo: "Reposta con el descuentazo",
            },
            dialogo: {
              comomeidentificocomousuario: "¿Cómo me identifico como usuario?",
              enelsurtidorselecciona: "En el surtidor, selecciona",
              identificarusuario: "Identificar usuario",
              mediantelascamarastrataremosde:
                "Mediante las cámaras trataremos de",
              reconocertumatrícula: "reconocer tu matrícula",
              sinolaconseguimosreconocerpodras:
                "Si no la conseguimos reconocer, podrás",
              introducirlamanualmente: "introducirla manualmente",
              enlapantalla: "en la pantalla",
              button: { entendido: "Entendido" },
            },
          },
          blackfinde: {
            header: "Black Finde",
            "hero-title-1": "Vuelve el ",
            "hero-title-red": "mayor descuento del año",
            "hero-title-2": " en combustible",
            "hero-subtitle":
              "Prepárate. Descarga nuestra app y registra tu matrícula.",
            "hero-button": "¡Empieza a ahorrar!",
            "hero-button-subtitle":
              "Descarga nuestra app y disfruta del descuento.",
            "info-title-1": "¿Aún no conoces el",
            "info-title-red": "Black Finde ",
            "info-title-2": "de Petroprix?",
            "description-1": "Llevamos petándolo cada año y ",
            "description-2": "este 2024 no va a ser distinto.",
            "description-3":
              "Algunas marcas hacen Black Friday... pero a nosotros nos gusta estirar los descuentos mucho más. ",
            "description-4": "Black Finde de Petroprix",
            "description-5":
              " es el único fin de semana en el que podrás repostar con el ",
            "description-6": "mayor descuento del año.",
            "card-dates-title": "Sácale partido a este descuento los días",
            "card-dates-img-alt": "29, 30 de noviembre y 1 de diciembre",
            "card-dates-year": "2024",
            "description-info-steps-h3": "¿Cómo tienes que activar la promo?",
            "description-info-steps-p-bold": "¡Es muy sencillo! ",
            "description-info-steps-p":
              "Sigue los siguientes pasos y podrás empezar a ahorrar repostando en nuestras gasolineras.",
            "card-1-title":
              "Instala la app de Petroprix y registra tu matrícula.",
            "card-1-description-1": "Descarga la app desde ",
            "card-1-description-2": "Google Play",
            "card-1-description-3": " o ",
            "card-1-description-4": "App Store",
            "card-1-description-5": " y registra la matrícula de tu vehículo.",
            "card-2-title":
              "Visita una gasolinera Petroprix e identifícate como usuario.",
            "card-2-description":
              "Podrás seguir el proceso de identificación desde la pantalla del surtidor donde vayas a repostar.",
            "card-3-title":
              "Escoge tu opción de repostaje y el descuento se aplicará.",
            "card-3-description":
              "Si te identificaste correctamente como usuario, el descuento del Black Finde se te aplicará de forma automática.​",
          },
          ruletanotfound: {
            problematecnico: "Problema técnico",
            error:
              "Tenemos un problema técnico con la ruleta, estamos trabajando para restablecer el servicio lo antes posible, seguís conservando las tiradas, el historial y todos los regalos ya asignados. Disculpad las molestias.",
          },
          aniversario10: {},
        },
      },
      pt: {
        translation: {
          sorteomensual: {
            header: "Sorteio mensal",
            noinfo: {
              nosehapodidocargarlainformacion:
                "Não foi possível carregar as informações",
              porfavorintentelomastarde:
                "Por favor, tente novamente mais tarde.",
            },
            nextcard: {
              h5: "PRÓXIMO SORTEIO",
              premio: "PRÊMIO",
              h2: { diadesorteo: "DIA DE SORTEIO", tusnumeros: "SEUS NÚMEROS" },
              errors: {
                aunnotienesnumeros: "Você ainda não tem números",
                nosepudocargarlainformacion:
                  "Não foi possível carregar as informações",
              },
            },
            lastcard: {
              h5: "ÚLTIMO SORTEIO",
              h2: { nganadores: "N.º VENCEDORES" },
              empty: "Ainda não há vencedores",
              errors: {
                sinnumerosparaestesorteo: "Não há números para este sorteio",
              },
              verificaciondesorteo: "Verificação de sorteio",
              info: {
                title: "Caso seu número seja concedido,",
                text: " entraremos em contato com você no endereço de e-mail associado à sua conta Petroprix para coordenar a entrega do prêmio.",
              },
            },
            buttons: {
              historial: "Histórico",
              sorteos: "sorteios",
              como: "Como",
              participar: "participar",
              bases: "Bases",
              legales: "legais",
              dialogoparticipar: {
                comofunciona: "Como funciona",
                elsorteo: "o sorteio?",
                cadavezquetiresenlaruletay:
                  "Cada vez que você jogar na roleta e",
                noobtengaspremioteasignaremosunnumero:
                  "não ganhar nenhum prêmio, iremos atribuir-lhe um número",
                queseravalidounicamenteparaelsorteomensualdeesemes:
                  "que será válido apenas para o sorteio mensal daquele mês.",
                losnumerossonacumulables: "Os números são cumulativos,",
                porloquepuedesparticiparconvariosalavez:
                  "então você pode participar com vários ao mesmo tempo.",
                apple:
                  "Apple não patrocina nem tem qualquer ligação com este sorteio.",
                entreelprimeryelsegundodialaborabledecadamesrealizaremosunsorteocertificado:
                  "Entre o primeiro e o segundo dia útil de cada mês, realizaremos um sorteio certificado",
                entretodoslosnumerosparticipantes:
                  "entre todos os números participantes",
                delosqueobtendremoslosganadoresdeformaaleatoria:
                  ", do qual obteremos os vencedores de forma aleatória.",
                siguiente: "Próximo",
                unavezobtenidoslosganadoresqueserantantoscomopremiossorteemosesemes:
                  "Assim que forem obtidos os vencedores, que serão tantos quantos os prêmios que sortearmos naquele mês,",
                compartiremoselcertificadodeverificacion:
                  "compartilharemos o certificado de verificação",
                ylosnumerosganadorestantoenla:
                  " e os números vencedores tanto no",
                comoennuestras: "quanto em nossas",
                redessociales: "redes sociais.",
                aceptar: "Aceitar",
              },
              dialogopremiado: {
                premioobtenido: "Prêmio obtido!",
                tunumero: "Seu número",
                hasido: "foi",
                premiadoenel: "premiado no",
                sorteomensualde: "sorteio mensal de",
                nospondremosencontactocontigomuyprontoparahacertellegartupremio:
                  "Entraremos em contato com você em breve para enviar\nseu prêmio",
              },
              history: {
                loading: "Carregando sorteios do ano",
                errors: { nohaysorteosesteano: "Não há brindes este ano" },
              },
            },
          },
          promotirada: {
            cargando: "Carregando",
            haocurridounerror: "Ocorreu um erro",
            tirada1: {
              bienvenidoalarevoluciondelossegurosdecoche:
                "Bem-vindo à revolução do seguro automóvel",
              encombustible: "EM COMBUSTÍVEL",
              engasolineras: "NAS ESTAÇÕES DE ABASTECIMIENTO",
              soloporpasartusegurodecocheahelloautotellevas:
                "Apenas transferindo o seguro do seu carro para Hello Auto, você consegue...",
              atras: "Voltar",
            },
            tirada2: {
              llevateunatirada: "Ganhe um giro",
              enlaruleta: "na ruleta",
              soloporcalcular: "apenas para calcular",
              elpreciodetupoliza: "o preço da sua apólice",
              gratis: { minusc: "Grátis", mayusc: "GRÁTIS" },
            },
            tirada3: {
              eslaprimeraaseguradoradondelosbuenosconductores:
                "É a primeira seguradora onde bons condutores",
              ahorrancadaano: "poupam todos os anos.",
              ademasrecibiras: "Além disso, você receberá",
              contuseguro: "COM SEU SEGURO",
              quees: "O que é",
            },
            tirada4: {
              button: {
                camara: "Câmera",
                asistente: "Assistente",
                radares: "Radares",
              },
              eselcopilotointeligenteconelque:
                "É o copiloto inteligente com quem você",
              podras: "pode:",
              scroll: {
                grabartodo: "Gravar tudo",
                loqueocurra: "o que acontece  ",
                mientrasconduces: "enquanto você dirige.",
                conocerla: "Conheça a",
                velocidadmaximadecadavia: "velocidade máxima de cada estrada",
                yalaquecirculasencadamomento:
                  "e com que você viaja em todos os momentos.",
                tener: "Tenha",
                serviciodeemergencia: "atendimento de emergência",
                entodomomentoy: "em todos os momentos e",
                llamadaautomaticadeemergencia:
                  "chamada de emergência automática.",
                detectarlosradaresantesquenadie:
                  "Detecte radares antes de qualquer outra pessoa.",
                localizartucocheentodomomento:
                  "Localize seu carro em todos os momentos.",
                teneraccesoatodaslasrutasquehasrealizado:
                  "Tenha acesso a todos os percursos que realizou",
                yconocerdatosdetodasellas: "e conheça dados sobre todos eles.",
              },
            },
            tirada5: {
              cuantopuedo: "Quanto posso  com",
              ahorrar: "economizar",
              con: "com",
              sabemosque: "Sabemos que",
              tegustaahorrar: "você gosta de economizar",
              yque: "e que",
              conducesmejorquenadie: "dirige melhor que ninguém.",
              enhelloautopuedestraertusegurodecochedesde:
                "Na Hello Auto você pode trazer o seguro do seu carro a partir de:",
              calculaahoratuseguroydescubrecualestuprecio:
                "Calcule já o seu seguro e descubra qual é o seu preço.",
              calcular: "Calcular",
              almes: "por mês",
            },
          },
          reyesmagos: {
            header: "Reis Magos",
            disfrutadeldescuento: "Aproveite o desconto!",
            verbases: "Veja bases",
            ahorra5centimosporlitro: "Economize 5 centavos por litro",
            cuando: "Quando",
            seisdeenero: "6 de janeiro",
            de2022: "de 2022",
            steps: {
              sigueestospasosparaconseguirtu:
                "Siga estas etapas para obter seu",
              descuento: "desconto",
              verificaquetienestumatricula:
                "Verifique se você tem sua matrícula",
              registradaenlaaplicacion: "cadastrada no aplicativo",
              sidesconocessitumatriculaestaregistradaonopuedes:
                "Se você não sabe se sua placa está registrada ou não, você pode",
              comprobarloaqui: "conferir aqui",
              cuandoestesenelsurtidorrecuerda:
                "Quando estiver na bomba, lembre-se de",
              identificartecomousuario: "se identificar como usuário",
              repostaconeldescuentazo: "Abasteça com o grande desconto",
              comomeidentificocomousuario:
                "Como posso me identificar como usuário?",
            },
            dialogo: {
              enelsurtidorselecciona: "Na bomba, selecione",
              identificarusuario: "Identificar usuário",
              mediantelascamarastrataremosde: "Usando as câmeras tentaremos",
              reconocertumatrícula: "reconhecer sua placa",
              sinolaconseguimosreconocerpodras:
                "Se não conseguirmos reconhecê-lo, você pode",
              introducirlamanualmente: "inseri-lo manualmente",
              enlapantalla: "na tela",
              button: { entendido: "Entendido" },
            },
          },
          blackfinde: {
            header: "Black Finde",
            "hero-title-1": "O maior ",
            "hero-title-red": "desconto de combustível",
            "hero-title-2": " do ano está de volta",
            "hero-subtitle":
              "Prepare-se. Baixe nosso aplicativo e registre sua inscrição.",
            "hero-button": "¡Comece a economizar!",
            "hero-button-subtitle":
              "Descarregue a nossa aplicação e aproveite o desconto.",
            "info-title-1": "Você ainda não ouviu falar da Petroprix",
            "info-title-red": " Black Weekend",
            "info-title-2": "?",
            "description-1": "Temos feito isso todos os anos e em ",
            "description-2": "2024 não será diferente.",
            "description-3":
              "Algumas marcas fazem a Black Friday... mas nós gostamos de estender os descontos para muito mais longe. ",
            "description-4": "O Petroprix Black Weekend",
            "description-5":
              " é o único fim de semana em que você pode abastecer com o ",
            "description-6": "maior desconto do ano.",
            "card-dates-title": "Aproveite este desconto em",
            "card-dates-img-alt": "29, 30 de novembro e 1 de dezembro",
            "card-dates-year": "2024",
            "description-info-steps-h3": "Como você precisa ativar a promoção?",
            "description-info-steps-p-bold": "É fácil! ",
            "description-info-steps-p":
              "Siga as etapas abaixo e você poderá começar a economizar ao abastecer em nossos postos de gasolina.",
            "card-1-title":
              "Instale o aplicativo Petroprix e registre sua placa de identificação.",
            "card-1-description-1": "Faça o download do aplicativo em ",
            "card-1-description-2": "Google Play",
            "card-1-description-3": " ou ",
            "card-1-description-4": "App Store",
            "card-1-description-5":
              " e registre o número de registro do seu veículo.",
            "card-2-title":
              "Visite um posto de gasolina Petroprix e faça login como usuário.",
            "card-2-description":
              "Você poderá acompanhar o processo de identificação na tela da bomba onde vai abastecer.",
            "card-3-title":
              "Escolha sua opção de reabastecimento e o desconto será aplicado.",
            "card-3-description":
              "Escolha sua opção de reabastecimento e o desconto será aplicado.",
          },
          ruletanotfound: {
            problematecnico: "Problema técnico",
            error:
              "Temos um problema técnico com a roleta, estamos trabalhando para restaurar o serviço o mais rápido possível, você continuará mantendo o rolos, história e todos os presentes já atribuídos. Com licença as moléstias.",
          },
          aniversario10: {},
        },
      },
    },
  });

export default i18n;
