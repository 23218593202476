import "./sorteo-mensual.css";
import getMonthNameByNumber from "../helpers/getNameByMonthNumber";
import { useTranslation } from "react-i18next";

export const ItemHistory = ({ history, accessToken }) => {
  let dateFull = history["fecha_sorteo"];
  let dateFullList = [];
  let dateMonthToName;
  let dateMonthNumber;

  const { t } = useTranslation();

  dateFullList = dateFull.split("-", 3);

  let dateFormatted =
    dateFullList[2] + "/" + dateFullList[1] + "/" + dateFullList[0];

  dateMonthNumber = parseInt(dateFullList[1]);
  dateMonthToName = dateMonthNumber - 1;
  if (dateMonthToName < 1) {
    dateMonthToName = 12;
  }

  return (
    <div className="sm-item-history">
      <div className="sm-title-container-item-history black">
        {getMonthNameByNumber(dateMonthToName.toString()).toUpperCase()}
      </div>
      <br></br>
      <h2 className="sm-titulos">{t("sorteomensual.nextcard.premio")}</h2>
      {history.imagen !== "" ? (
        <img
          className="sm-img-premio"
          alt={history.nombre}
          src={`data:image/png;base64,${history.imagen}`}
        />
      ) : (
        <span></span>
      )}
      <div className="sm-text-center sm-color-black-blue">
        <span className="regular">{history.nombre}</span>
      </div>
      <br></br>
      <div className="sm-date-container-history-item">
        <span className="black color-black-blue">
          {t("sorteomensual.nextcard.h2.diadesorteo")}
        </span>
        <br></br>
        <span className="regular sm-mt-1-block"> {dateFormatted}</span>
      </div>
      <br />
      <br />
    </div>
  );
};
