import { useSearchParams } from 'react-router-dom';
import './black-friday.css';
import Timer from './Timer';
export const BlackFridayPrevious = () => {
  const [searchParams] = useSearchParams();
  let accessToken = searchParams.get('token');

  if (!accessToken) {
    accessToken = '';
  }
  return (
    <>
      <Timer accessToken={accessToken} />
    </>
  );
};
