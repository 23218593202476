import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HelloAutoAppBar } from "../components/app_bar_helloauto";
import cloud from "../assets/images/tirada/thought.png";
import iconCamera from "../assets/images/tirada/camara.svg";
import iconSenal from "../assets/images/tirada/senal4.svg";
import iconSos from "../assets/images/tirada/sos.svg";
import iconRed from "../assets/images/tirada/red.svg";
import iconLocalizacion from "../assets/images/tirada/localizacion.svg";
import iconLupa from "../assets/images/tirada/lupa.svg";
import bBox from "../assets/images/tirada/bbox.png";
import "./tirada.css";
import { useTranslation } from "react-i18next";

export const TiradaGratisHelloAuto4 = () => {
  const [focus, setFocus] = useState(0);
  const [url, seturl] = useState("videos/tirada/Camara.mp4");
  const [autoplay, setautoplay] = useState(false);

  const location = useLocation();
  const history = useNavigate();
  const { t } = useTranslation();

  let data = location.state.data;

  function handleClickNext() {
    history("/tirada5", {
      state: {
        data: data,
        os: location.state.os,
      },
    });
  }

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }
  function handleClickBefore() {
    history(-1);
  }

  useEffect(() => {
    setTimeout(() => {
      setFocus(1);
      setautoplay(true);
    }, 200);
  }, []);

  useEffect(() => {
    switch (focus) {
      case 1:
        seturl("videos/tirada/Camara.mp4");
        break;
      case 2:
        seturl("videos/tirada/sos.mp4");
        break;
      case 3:
        seturl("videos/tirada/asistente.mp4");
        break;
      case 4:
        seturl("videos/tirada/Radar.mp4");
        break;
      default:
        seturl("videos/tirada/Camara.mp4");
        break;
    }
  }, [focus]);

  const setFocus1 = () => {
    setFocus(1);
  };
  const setFocus2 = () => {
    setFocus(2);
  };
  const setFocus3 = () => {
    setFocus(3);
  };
  const setFocus4 = () => {
    setFocus(4);
  };

  return (
    <>
      <HelloAutoAppBar
        className="th-appbartirada4"
        title="Promo Hello Auto"
        onBackClick={onBackClick}
        backgroundColor="#018bed"
      ></HelloAutoAppBar>
      <div className="th-parent-ha4">
        <div id="ha-clipped-container" className="th-clipped-container-ha">
          {" "}
          &nbsp;
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="th-icon-box-ha4">
          <img src={cloud} className="th-thinking-icon-ha" alt="cloud" />
          <div className="th-icon-box-ha-text">
            <span className="silka-regular">¿Qué es </span>
            <span className="silka-black"> Hello Auto Connect</span>
            <span className="silka-regular">? </span>
          </div>
        </div>
        <br></br>

        {/* autoPlay con usestate
         */}
        <div className="th-bbox-separation"></div>
        <div className="th-stack" key={focus}>
          <video
            id="videotag"
            poster={bBox}
            key={url}
            style={{
              width: "100vw",
              height: "30vh",
            }}
            autoPlay={autoplay}
            loop
            muted
            preload="false"
            tabIndex="0"
            playsInline
            webkit-playsinline="true"
          >
            <source src={url} type="video/mp4" />
          </video>
        </div>
        <div className="th-four-buttons">
          <div className="th-four-buttons-row">
            <div
              className="th-button-item"
              onClick={setFocus1}
              style={{
                color: focus === 1 && "#018bed",
                backgroundColor: focus === 1 && "white",
                border: focus === 1 && "2px solid #018bed",
                borderRadius: focus === 1 && "5px",
              }}
            >
              <div className="th-reborde"></div>
              <span className="th-button-item-text silka-regular">
                {" "}
                {t("promotirada.tirada4.button.camara")}
              </span>
            </div>
            <div
              className="th-button-item"
              onClick={setFocus2}
              style={{
                color: focus === 2 && "#018bed",
                backgroundColor: focus === 2 && "white",
                border: focus === 2 && "2px solid #018bed",
                borderRadius: focus === 2 && "5px",
              }}
            >
              <div className="th-reborde"></div>
              <span className="th-button-item-text silka-regular"> SOS</span>
            </div>
          </div>
          <div className="th-four-buttons-row">
            <div
              className="th-button-item"
              onClick={setFocus3}
              style={{
                color: focus === 3 && "#018bed",
                backgroundColor: focus === 3 && "white",
                border: focus === 3 && "2px solid #018bed",
                borderRadius: focus === 3 && "5px",
              }}
            >
              <div className="th-reborde"></div>
              <span className="th-button-item-text silka-regular">
                {" "}
                {t("promotirada.tirada4.button.asistente")}
              </span>
            </div>
            <div
              className="th-button-item"
              onClick={setFocus4}
              style={{
                color: focus === 4 && "#018bed",
                backgroundColor: focus === 4 && "white",
                border: focus === 4 && "2px solid #018bed",
                borderRadius: focus === 4 && "5px",
              }}
            >
              <div className="th-reborde"></div>
              <span className="th-button-item-text silka-regular">
                {" "}
                {t("promotirada.tirada4.button.radares")}
              </span>
            </div>
          </div>
        </div>
        <div className="silka-light th-box-text-ha4">
          {t("promotirada.tirada4.eselcopilotointeligenteconelque")}
          <br></br>
          {t("promotirada.tirada4.podras")}
        </div>
        <div className="th-horizontal-scroll-ha">
          <div className="th-item-horizontal-scroll-ha">
            <img
              src={iconCamera}
              className="th-icon-item-horizontal-scroll-ha"
              alt="icon"
            />
            <br></br>
            <div className="th-text-item-horizontal-scroll-ha">
              <span className="silka-bold">
                {t("promotirada.tirada4.scroll.grabartodo")}{" "}
              </span>
              <span className="silka-regular">
                {t("promotirada.tirada4.scroll.loqueocurra")}{" "}
              </span>
              <span className="silka-bold">
                {t("promotirada.tirada4.scroll.mientrasconduces")}
              </span>
            </div>
          </div>
          <div className="th-item-horizontal-scroll-ha">
            <img
              src={iconSenal}
              className="th-icon-item-horizontal-scroll-ha"
              alt="icon"
            />
            <br></br>
            <div className="th-text-item-horizontal-scroll-ha">
              <span className="silka-bold">
                {t("promotirada.tirada4.scroll.conocerla")}{" "}
              </span>
              <span className="silka-regular">
                {t("promotirada.tirada4.scroll.velocidadmaximadecadavia")}{" "}
              </span>
              <span className="silka-bold">
                {t("promotirada.tirada4.scroll.yalaquecirculasencadamomento")}
              </span>
            </div>
          </div>
          <div className="th-item-horizontal-scroll-ha">
            <img
              src={iconSos}
              className="th-icon-item-horizontal-scroll-ha"
              alt="icon"
            />
            <br></br>
            <div className="th-text-item-horizontal-scroll-ha">
              <span className="silka-regular">{t("tener")} </span>
              <span className="silka-bold">
                {t("promotirada.tirada4.scroll.serviciodeemergencia")}{" "}
              </span>
              <span className="silka-regular">
                {t("promotirada.tirada4.scroll.entodomomentoy")}{" "}
              </span>
              <span className="silka-bold">
                {t("promotirada.tirada4.scroll.llamadaautomaticadeemergencia")}
              </span>
            </div>
          </div>
          <div className="th-item-horizontal-scroll-ha">
            <img
              src={iconRed}
              className="th-icon-item-horizontal-scroll-ha"
              alt="icon"
            />
            <br></br>
            <div className="th-text-item-horizontal-scroll-ha">
              <span className="silka-bold">
                {t(
                  "promotirada.tirada4.scroll.detectarlosradaresantesquenadie"
                )}
              </span>
            </div>
          </div>
          <div className="th-item-horizontal-scroll-ha">
            <img
              src={iconLocalizacion}
              className="th-icon-item-horizontal-scroll-ha"
              alt="icon"
            />
            <br></br>
            <div className="th-text-item-horizontal-scroll-ha">
              <span className="silka-bold">
                {t("promotirada.tirada4.scroll.localizartucocheentodomomento")}
              </span>
            </div>
          </div>
          <div className="th-item-horizontal-scroll-ha">
            <img
              src={iconLupa}
              className="th-icon-item-horizontal-scroll-ha"
              alt="icon"
            />
            <br></br>
            <div className="th-text-item-horizontal-scroll-ha">
              <span className="silka-bold">
                {t(
                  "promotirada.tirada4.scroll.teneraccesoatodaslasrutasquehasrealizado"
                )}{" "}
              </span>
              <span className="silka-regular">
                {t("promotirada.tirada4.scroll.yconocerdatosdetodasellas")}
              </span>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="th-row-button-ha-parent">
          <div className="th-row-buttons-ha">
            <div
              className="th-boton-siguiente-ha2 silka-bold"
              onClick={handleClickBefore}
            >
              {t("promotirada.tirada1.atras")}
            </div>
            <div
              className="th-boton-siguiente-ha1 silka-bold"
              onClick={handleClickNext}
            >
              {t("sorteomensual.buttons.dialogoparticipar.siguiente")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
