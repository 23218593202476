import Modal from "react-modal";
import logo10a from "../assets/images/aniversario10/10a_logo.svg";
import './aniversario10.css';

Modal.setAppElement("#root");

export const DialogInfoAniversario10 = ({ isOpen, toggleModal, data }) => {
    let dia_inicio = data.fechaInicioPromo.split(' ')[0];

    function BasesLegales() {
        window.navigation.postMessage("web,https://petroprix.com/10aniversario/terminos-y-condiciones/,,,");
  }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodalaniversario10 modalaniversario10"
            overlayClassName="myoverlay"
            closeTimeoutMS={200}
        >

            <div>

                <div className="anv-content-dialog">
                    <div className="center">
                    <span className="anv-dialog-title black" >¡Petroprix cumple</span>
                        <span className="anv-dialog-logo"><img src={logo10a} alt="" /></span>
                        <span className="anv-dialog-footer black" >años!</span>
                    </div>
                    <div className="anv-promo-info">
                        <p className="anv-dialog-info black">Para celebrarlo ofrecemos el mayor descuento que hayamos dado nunca</p>
                        <p className="anv-dialog-info regular">Del {dia_inicio} al {data.fechaFinFase0} regalamos 10 años de combustible gratis cada día con tus repostajes. Además cuanto más repostes durante esos días, más litros acumulas con descuento de 10 cént./L. </p>
                        <p className="anv-dialog-info link bold" onClick={BasesLegales}>Consulta las bases del aniversario aquí.</p>
                        <p className="anv-dialog-info small">(El descuento acumulado lo podrás usar del {data.fechaInicioFase1} al {data.fechaFinPromo})</p>
                    </div>
                    <div className="anv-dialog-button" onClick={toggleModal}>¡Entendido!</div>
                </div>
            </div>
        </Modal>
    );
}

