import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HelloAutoAppBar } from "../components/app_bar_helloauto";
import nestor from "../assets/images/tirada/nestor.png";
import thinking from "../assets/images/tirada/thinking.png";
import "./tirada.css";
import { useTranslation } from "react-i18next";

export const TiradaGratisHelloAuto3 = () => {
  const history = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();

  let data = location.state.data;

  function handleClickNext() {
    history("/tirada4", {
      state: {
        data: data,
        os: location.state.os,
      },
    });
  }

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }

  function handleClickBefore() {
    history(-1);
  }

  return (
    <div className="th-parent-container">
      <HelloAutoAppBar
        title="Promo Hello Auto"
        onBackClick={onBackClick}
        backgroundColor="#00"
      ></HelloAutoAppBar>
      <img src={nestor} className="th-img-hello-nestor" alt="nes" />
      <div className="th-icon-box-ha">
        <img src={thinking} className="th-thinking-icon-ha" alt="thinking" />
        <span className="silka-black th-icon-box-ha-text">
          {t("promotirada.tirada3.quees")} Hello Auto?
        </span>
      </div>
      <br></br>
      <div className="th-box-text-ha">
        <span className="silka-regular">
          {t(
            "promotirada.tirada3.eslaprimeraaseguradoradondelosbuenosconductores"
          )}{" "}
        </span>
        <span className="silka-bold">
          {t("promotirada.tirada3.ahorrancadaano")}
        </span>
      </div>
      <div>
        <span className="silka-bold">
          {t("promotirada.tirada3.ademasrecibiras")}
        </span>
      </div>
      <div className="th-div-icon-on3">
        <br></br>
        <div className="silka-bold">HELLO AUTO CONNECT</div>
        <div className="silka-black th-text-gratis">
          {" "}
          {t("promotirada.tirada2.gratis.mayusc")}{" "}
        </div>
        <div className="silka-regular">
          {" "}
          {t("promotirada.tirada3.contuseguro")}{" "}
        </div>
      </div>
      <div className="th-row-button-ha-parent">
        <div className="th-row-buttons-ha">
          <div
            className="th-boton-siguiente-ha2 silka-bold"
            onClick={handleClickBefore}
          >
            {t("promotirada.tirada1.atras")}
          </div>
          <div
            className="th-boton-siguiente-ha1 silka-bold"
            onClick={handleClickNext}
          >
            {t("sorteomensual.buttons.dialogoparticipar.siguiente")}
          </div>
        </div>
      </div>
    </div>
  );
};
